/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Styles } from './Styles'
import { Link as ScrollLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import 'rc-slider/assets/index.css';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SearchTextField } from './SearchTextField';
import Tooltip from '@material-ui/core/Tooltip';
import { adminData } from '../../data/AdminData';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export class EventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  getInfoString = (item) => {
    const minutes = moment().diff(moment(item.event_time), 'minutes')
    if (minutes > 60) {
      const hours = moment().diff(moment(item.event_time), 'hours')
      if (hours > 24) {
        const days = moment().diff(moment(item.event_time), 'days')
        return days + ' days ago'
      } else {
        return hours + ' hrs ago'
      }
    } else if (minutes > 0) {
      return minutes + ' minutes ago'
    }
  }

  getEventTileStyles = (item) => {
    if (item.metadata && item.metadata.groupId && this.props.eventsByGroupId[item.metadata.groupId] && this.props.eventsByGroupId[item.metadata.groupId].length > 0) {
      if (item._id === this.props.selectedEvent._id) {
        return css(Styles.eventListStackContent, Styles.eventListContentSelected)
      } else {
        return css(Styles.eventListStackContent)
      }
    } else {
      if (item && this.props.selectedEvent && item._id === this.props.selectedEvent._id) {
        return css(Styles.eventListContent, Styles.eventListContentSelected)
      } else {
        return css(Styles.eventListContent)
      }
    }
  }

  eventList = (data) => {
    return (
      <Element id='event-scroll-container' className={css(Styles.innerEventListContent)}>
        {data &&
          data.map((item, index) => {
            return (
              <Element
                key={index}
                name={item._id}
                className={this.getEventTileStyles(item)}
                onClick={() => this.props.onEventTileSelect(item, index)}>
                {item.image ?
                  <div className={css(Styles.eventListImageConatiner)} >
                    <img
                      key={item.image}
                      src={item.image}
                      onError={(e) => {
                        e.target.src = require('../../assets/images/error2.svg')
                      }}
                      className={css(Styles.eventImg)} />
                  </div>
                  :
                  <div className={css(Styles.eventImageLoading)}>
                    <p className={css(Styles.eventListTitle)}>Image loading...</p>
                  </div>
                }
                <div className={css(Styles.eventListTitleConatiner)}>
                  {item.name &&
                    <p className={css(Styles.eventListTitle)}>{item.name}</p>
                  }
                  <div className={css(Styles.eventListDateContainer)}>
                    <p className={css(Styles.eventListDate)}>
                      {this.getInfoString(item)}</p>
                    {adminData.priorityValues.map((value, i) => {
                      return (
                        value.value === item.priority &&
                        <div key={i} className={css(Styles.editAlertDotContent)}
                          style={{ backgroundColor: value.color }} />
                      )
                    })}
                  </div>
                  <p className={css(Styles.eventListDateStr)}>{moment(item.event_time).format('MMM DD, YYYY')}</p>
                  <p className={css(Styles.eventListDateStr)}>{moment(item.event_time).format('h:mm:ss A')}</p>
 
                </div>
        
              </Element>
            )
          })}
      </Element>
    )
  }

  getEventCount = () => {
    //console.log('pageNumber', this.props.pageNumber)
    return 'Page ' + (this.props.pageNumber + 1)
  }

  getPaginationData = () => {
    return (
      <div className={css(Styles.paginationContainer)}>
        <Tooltip title='Previous'>
          <IconButton disabled={this.props.pageNumber === 0} className={css(Styles.refreshIconContainer)} onClick={() => this.props.updateEvents('prev')}>
            <Icon className={css(Styles.refreshIcon)} color='primary' >
              navigate_before
            </Icon>
          </IconButton>
        </Tooltip>
        <p className={css(Styles.pageNumber)}>{this.getEventCount()}</p>
        <Tooltip title='Next'>
          <IconButton disabled={!this.props.paginate} className={css(Styles.refreshIconContainer)} onClick={() => this.props.updateEvents('next')}>
            <Icon className={css(Styles.refreshIcon)} color='primary' >
              navigate_next
            </Icon>
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  render = () => {
    return (
      <div className={(this.props.relativeImageSliderMode) ?
        css(Styles.innerEventListContainerRelativeOpen) : css(Styles.innerEventListContainer)} >
        <div  className={css(Styles.paginationContainer)}>

        <div className={css(Styles.innerSearchContainer)}>
          <SearchTextField
            searchHandler={this.props.searchHandler}
            disableSearchIcon={true}
            deepSearch={this.props.deepSearch}
          />

        </div>
        <Button
          variant='contained'
          onClick={()=>this.props.deepSearch()}
          className={css(Styles.buttonStyle2)}
        >
          SEARCH
        </Button>
        </div>

        <div className={css(Styles.eventListTopContainer)}>
          {this.getPaginationData()}
          <p className={css(Styles.innerSearchContent)}>{this.props.eventList.length > 0 ?
            'Showing ' + this.props.eventList.length + ' results' : (this.props.eventListLoading ? '' : ' No match found')}</p>
          {this.props.dateMode === 'recent' &&
            <div className={css(Styles.eventDateRangeContainer)}>
              <div className={css(Styles.timeContainer)}>
                <p className={css(Styles.eventItemDate)}>{moment(this.props.lastEventTs).format('MMM DD, YYYY')}</p>
                <p className={css(Styles.eventItemDate)}>{moment(this.props.lastEventTs).format('h:mm A')}</p>
              </div>
              <p className={css(Styles.eventItemDateDivider)}>TO</p>
              <div className={css(Styles.timeContainer)}>
                <p className={css(Styles.eventItemDate)}>{moment(this.props.latestEventTs).format('MMM DD, YYYY')}</p>
                <p className={css(Styles.eventItemDate)}>{moment(this.props.latestEventTs).format('h:mm A')}</p>
              </div>
            </div>

          }
        </div>

        {this.props.eventListLoading ?
          <CircularProgress
            color='secondary'
            size={40}
            className={css(Styles.progress)} />
          :
          this.eventList(this.props.eventList)
        }

      </div>
    )
  }
}