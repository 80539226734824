/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
/* uv-prod */
export const AppConfigCustom = {
  "APPAK": "web-2d346a36-02a4-11ec-9a03-0242ac130003",                             //web-key from incomingAPIs.json
  "CLOUD_REGION": "ap-south-1",
  "COGNITO_USER_POOL": "ap-south-1_kdAwnyS9U",
  "COGNITO_USER_POOL_CLIENT_ID": "c6n408gp9rj5pm05mtgl2p7ab",
  // "COGNITO_USER_POOL_CLIENT_ID": "5r7ls6f6n3rin5mukjpmee2ucq", // Redirects to localhost:8200 used in testing locally
  // "ADDITIONAL_HEADERS" : { 
    //   "x-apigw-api-id":"eojz01oil7"
    // },
  "ENDPOINT": "https://api.sandbox.remotifai.com",     //Invoke URL of ApiGateway
  // "ES_ENDPOINT": "https://api.sandbox.remotifai.com",   //Invoke URL of ApiGateway
	// "ENDPOINT": "https://alb.api.uncannyvision.ai/Prod",
  // "ES_ENDPOINT": "https://alb.api.uncannyvision.ai/Prod",
  // "JM_PROXY_ENDPOINT": "https://jm.dashboard.uncannyvision.ai:8081",
  "allowCamConfig": false,
  "allowLocal": false,
  "emailSuffix" : "@remotifai.com",
  "ENABLE_REFRESH_VIOLATION":true,
  "ENABLE_ALARM_NOTIFICATION":true,
  "ENABLE_ADVANCE_SETTINGS":true,
  "ENABLE_FR_SETTINGS":true,
  "SHOW_FOOTER_INFO":true,
  "advancedShieldSettingUrl":"https://settings.sandbox.remotifai.com/",
  "frontendSecretKey":"uvsandbox-dashboard",
  "loginURL":"https://sandbox.remotifai.com/login/cm",
  "eevmsLoginURL":"https://sandbox.remotifai.com/login/vms",
  "logoName":"cameramanager",
  "headerLogoName":"een-logo-white",
  "product":"cm",
  "cmLoginURL":"https://auth.test.cameramanager.com/oauth/authorize?scope=write&client_id=devtest1&response_type=code&redirect_uri=",
  "vmsLoginURL":"https://auth.eagleeyenetworks.com/oauth2/authorize?scope=vms.all&client_id=5aabc980ded14a768827a287cdb7a140&response_type=code&redirect_uri=",
  "allowAuditConfig":true,
  "allowEventReviews":true,
  "autoUpdateEvent": false,
  "eventQueryLimit": 1000,
  "eventDownloadLimit": 1000,
  "allowBoundingBox":false,
  "camFilterStatusTimer":5, // in mins
  "camFilterStatusEnabled":true,
  "dashboardEventQueryLimit": 100,
  "eventRefreshInterval": 10000,
  "imageFeedRefreshInterval": 6000,
  "parkingFeedRefreshInterval": 5000,
  "jpegPlayerRefreshInterval": 2000,
  "IDLE_TIMEOUT" : 1000*60*120,
  "SHOW_DIRECT_LINK_EVENT":false,
  "SHOW_VIOLATION":true,
  "SHOW_VIDEO":true,
  "ENABLE_REPORT_DOWNLOAD": true,
  "PDF_LIMIT":9000,
  "EXCEL_LIMIT":30000,
  "NAME": "Eagle Eye Networks",
  "mapK": "AhBVLeZEl9lnaie15GuW_pVIAUAMcmsWT-IGiDBmVLmBIVsaG0hMn9bm6ZN_yvDj",
  "viewMode": "List",
  "adminDashboard": "/orgs",
  "ES_EVENT_TYPES": {},
  "DEFAULT_ZONEINFO": 'Asia/Calcutta',
  "userDefaultSettings": {
    ALERT_EMAIL: "ON",
    ALERT_TELEGRAM:"ON",
    EVENT_VISIBILITY: "0",
    EVENT_EMAIL_ALERT: "3",
    CAMERAS: [],
    CAMERAS_TELEGRAM: [],
  },
  "userRoles": [
    {
      name: 'ADMIN',
      key: 'ADMIN',
    },
    {
      name: 'REGULAR',
      key: 'REGULAR',
    },
    {
      name: 'RESIDENT',
      key: 'RESIDENT',
    },
  ],
  placeTypes: [],
  camTypes: [],
  orgTypes: []
}