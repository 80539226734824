/* eslint-disable */
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';
import async from 'async';
import Icon from '@material-ui/core/Icon';
import { Button, Input, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { CheckboxComponent } from '../../components/CheckboxComponent';
import { EventActionHeaderbar } from '../ClientPlace/EventActionHeaderBar';
import { FileDownloader } from '../../common/helpers/FileDownloader';
import { SearchHelper } from '../../helpers/SearchHelper';
import { AppHelper } from '../../helpers/AppHelper.js'
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { UiHelper } from '../../common/helpers/UiHelper';
import { adminData } from '../../data/AdminData.js';
import { appData } from '../../data/AppData.js';
import { AppConfig } from '../../AppConfig';
import { CommonStyles } from '../../styles/Styles';
import { AppTheme } from '../../styles/AppTheme';
import { ColourPalette } from '../../styles/AppColourPalette';
import { Event } from '../ClientPlace/Event';
import { ChartComponent } from '../ClientPlace/ChartComponent';
import InputLabel from '@material-ui/core/InputLabel';
import '../../index.css'
import { SingleSelect } from '../../components/SingleSelect';
import { Styles as style } from '../../components/Styles'
import { Controller } from '../../common/Controller.js';


export class OrgReports extends Component {
  constructor(props) {
    super(props);
    this.orgId = this.props.match.params.id ? this.props.match.params.id : null;
    this.eventsByGroupId = {}
    this.grpDisplayMode = true
    this.dateMode = 'recent'
    this.responseByKey = {
      selectedPlaceIds: [],
      selectedCamIds: [],
      toDateTs: null,
      fromDateTs: null,
      eventFields: [],
      eventImages: [],
      priorityFilters: [],
      tagFilters: [],
    }
    this.tagValue = {}
    this.pdfAttrColWidth = {
      camId: 80,
      // eslint-disable-next-line camelcase
      event_time: 120,
      name: 90,
      type: 90,
      tags: 120,
      priority: 5,
      // priorityReported: 5,
    }
    this.eventsByType = {}
    this.state = {
      fetchState: ApiHelper.State.LOADING,
      eventList: [],
      eventCompleteList: [],
      eventListByDate: [],
      eventListLoading: false,
      pageNumber: 0,
      paginate: false,
      downloadDialog: false,
      downloadDialogErrorTitle: '',
      downloadDialogErrorDesc: '',
      showEvent: false,
      notToPrint: false,
      showChart: false,
      totalCountOfEvents:0,
      loadReportsExcel:false,
      loadReportsPDF:false,
      isFilterApplied:false,
      indexOfFile:-1,
      timezone : AppConfig.DEFAULT_ZONEINFO,
    }
    this.placePicker = {
      key: 'selectedPlaceIds',
      type: 'checkbox',
      options: [],
    }
    this.chartData = [
    ],
    this.eventListByDate = [],
    this.typeOfFile = ''
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.fetchItems()
  }

  fetchItems() {
    this.setState({
      fetchState: ApiHelper.State.LOADING
    })

    ApiHelper.call(
      { method: 'GET', endPoint: ApiHelper.makeUrlPath(['places'], { orgId: this.orgId }) },
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        results.sort((a, b) => a.name.localeCompare(b.name));
        this.setFields(results)

        this.setState({
          org: results,
          place: results.length > 0 ? results[0] : null,
          fetchState: ApiHelper.State.READY
        }, () => {
          //  this.updateEvents() 
          }
        )
      }.bind(this))
  }

  applyFilters = ()=>{
    this.setState({
      isFilterApplied:true
    })
    this.updateEvents()
  }

  setFields = (results) => {
    appData.reportFields.selectCam.options = []
    const selectAllField = AppHelper.getSelectAllOption()
    if (!results) {
      for (const o of this.state.org) {
        o.cams.sort((a, b) => a.name.localeCompare(b.name));
        if (this.responseByKey.selectedPlaceIds.indexOf(o._id) >= 0) {
          if (o.cams && o.cams.length > 0) {
            if (appData.reportFields.selectCam.options.indexOf(selectAllField) === -1) {
              appData.reportFields.selectCam.options.push(selectAllField)
            } //NOTE: Adding select all to cam fields
            for (const cam of o.cams) {
              appData.reportFields.selectCam.options.push({
                key: cam._id,
                name: cam.name
              })
            }
          }
        }
      }
      const selectedCamIds = []
      for (const placeId of this.responseByKey.selectedPlaceIds) {
        for (const o of this.state.org) {
          if (o._id === placeId) {
            for (const cam of o.cams) {
              if (this.responseByKey.selectedCamIds.indexOf(cam._id) >= 0) {
                selectedCamIds.push(cam._id)
              }
            }
          }
        }
      }
      this.responseByKey.selectedCamIds = selectedCamIds
      return
    }

    appData.reportFields.eventFields.options = adminData.eventFields
    appData.reportFields.eventImages.options = adminData.eventImages

    if (this.props.location.state && this.props.location.state.selectedPlaceIds) {
      this.responseByKey.selectedPlaceIds = this.props.location.state.selectedPlaceIds
    }
    if (this.props.location.state && this.props.location.state.selectedCamIds) {
      this.responseByKey.selectedCamIds = this.props.location.state.selectedCamIds
    }
    if (this.props.location.state && this.props.location.state.toDateTs) {
      this.responseByKey.toDateTs = this.props.location.state.toDateTs
    }
    if (this.props.location.state && this.props.location.state.fromDateTs) {
      this.responseByKey.fromDateTs = this.props.location.state.fromDateTs
    }
    if (this.props.location.state && this.props.location.state.tagValue) {
      this.tagValue = this.props.location.state.tagValue
    }
    if (this.props.location.state && this.props.location.state.tagFilters) {
      this.responseByKey.tagFilters = this.props.location.state.tagFilters
    }
    if (this.props.location.state && this.props.location.state.priorityFilters) {
      this.responseByKey.priorityFilters = this.props.location.state.priorityFilters
    }

    if (results && results.length > 0) {
      if (this.responseByKey.selectedPlaceIds && this.responseByKey.selectedPlaceIds.length === 0) {
        this.responseByKey.selectedPlaceIds = [results[0]._id]
        if (results[0] && results[0].cams.length > 0) {
          this.responseByKey.selectedCamIds = [results[0].cams[0]._id]
        } else {
          this.responseByKey.selectedCamIds = []
        }
      } else if (this.responseByKey.selectedCamIds && this.responseByKey.selectedCamIds.length === 0) {
        this.responseByKey.selectedCamIds = []
        for (const placeId of this.responseByKey.selectedPlaceIds) {
          for (const r of results) {
            if (r._id === placeId && r.cams && r.cams.length !== 0) {
              this.responseByKey.selectedCamIds.push(r.cams[0]._id)
            }
          }
        }
      }
    }

    this.placePicker.options = []
    if (this.placePicker.options.indexOf(selectAllField) === -1 && results && results[0]) {
      this.placePicker.options.push(selectAllField)
    }
    if (appData.reportFields.selectCam.options.indexOf(selectAllField) === -1 &&
      results && results[0] &&
      results[0].cams &&
      results[0].cams.length > 0) {
      appData.reportFields.selectCam.options.push(selectAllField)
    }

    for (const placeId of this.responseByKey.selectedPlaceIds) {
      for (const r of results) {
        r.cams.sort((a, b) => a.name.localeCompare(b.name));
        this.placePicker.options.push({
          key: r._id,
          name: r.name
        })
        if (r._id === placeId) {
          for (const cam of r.cams) {
            appData.reportFields.selectCam.options.push({
              key: cam._id,
              name: cam.name
            })
          }
        }
      }
    }
  }

  	
  getDateBlocks = (start, end, maxDays) => {
    let result = [];
    let s = new Date(start);
    while (s < end) {
      let e = new Date(s.getFullYear(), s.getMonth(), s.getDate()+ maxDays, s.getHours(), s.getMinutes(), s.getSeconds() );
      result.push({start:new Date(s), end: e <= end? e : new Date(end)});
      s.setDate(s.getDate() + maxDays , s.getMinutes()+1);
    }
    return result;
  }
  
  batchApiProcess = (callback2, isExcel, pword) =>{
    if(this.state.totalCountOfEvents >= 10000){
     let fromDateTs = this.responseByKey.fromDateTs	
     let toDateTs = this.responseByKey.toDateTs	
     let dateChunks = this.getDateBlocks(new Date(fromDateTs), new Date(toDateTs), 10)
     let fncs = []
      
     for (let index = 0; index < dateChunks.length; index++) {
       let element = dateChunks[index]
      fncs.push( (callback) => {	
        this.fetchEventsByDate((val)=>{
          callback(null, {})
        }, isExcel, pword, element.start.valueOf(),element.end.valueOf() )
     })    
     }
     async.series(fncs, (err, res)=>{
       callback2()
     })
    }
  }

  // apiCaller = (options,callback, retries) =>{
  //   // ApiHelper.call(options, callback)	
  //   ApiHelper.call(options, (err,data)=>{
  //     if(data){
  //      return callback(null, data)
  //     }else{
  //       if(retries> 0){
  //         return  this.apiCaller(options, callback, retries-1)
  //       }else{
  //         return callback(err)
  //       }
  //     }
  //   })	
  // }

  fetchEventsByDate = (callback2, isExcel, pword) => {	
    const params = {	
      priority: AppHelper.getGlobalPriotiyValue(),	
    }	
    params.fromDateTs = this.responseByKey.fromDateTs	
    params.toDateTs = this.responseByKey.toDateTs	
    params.limit = 0	
    params.disableImageSign = true
    params.pageNumber = 0	
    params.camId = this.responseByKey.selectedCamIds	
    if (params.camId.includes(AppHelper.getSelectAllOption().key)) {	
      params.camId = [AppHelper.getSelectAllOption().key]	
      params.placeIds = this.responseByKey.selectedPlaceIds	
      params.placeIds = params.placeIds.filter(function (value) {	
        return !value.includes((AppHelper.getSelectAllOption()).key)	
      });	
    }	
    if (this.responseByKey.priorityFilters && this.responseByKey.priorityFilters.length > 0) {	
      params['priorityArr'] = JSON.stringify(this.responseByKey.priorityFilters)	
    }	
    const thisObj = this	
    const fncs = []	
    for (let index = 0; index < Math.ceil(this.state.totalCountOfEvents / AppConfig.eventDownloadLimit); index++) {	
      params.limit = AppConfig.eventDownloadLimit	
      params.pageNumber = index	
      const options = {	
        method: 'GET',	
        endPoint: ApiHelper.makeUrlPath(['camevents'], params)	
      }	
      fncs.push(function (callback) {	
         if((index*AppConfig.eventDownloadLimit ) % (isExcel ? AppConfig.EXCEL_LIMIT : AppConfig.PDF_LIMIT) == 0){
          thisObj.setState({
             indexOfFile : thisObj.state.indexOfFile+1
           })
         }
        ApiHelper.call(options, callback)	
        // thisObj.apiCaller(options,callback,5)
      })	
      
    }	
    const awsParams = {	
      camIds: params.camId,	
      placeIds: params.placeIds	
    }	
    if (params.fromDateTs && params.toDateTs) {	
      awsParams.fromTs = params.fromDateTs	
      awsParams.toTs = params.toDateTs	
    }	
    if (AppConfig.ES_ENDPOINT) {	
      const awsOptions = {	
        method: 'GET',	
        endPoint: ApiHelper.makeUrlPathAws(['events'], awsParams)	
      }	
      fncs.push(function (callback) {	
        ApiHelper.call(awsOptions, callback)	
      })	
    }	
    async.series(	
      fncs,	
      function (err, results) {	
        if (err) {	
          callback2("defaultErrorMsg")	
          return	
        }
        if(results.length == 0){
          callback2("noEventsFound")	
          return
        }	
        let result = []	
        for (let index = 0; index < results.length; index++) {	
          const element = results[index].items;	
          if (element) {	
            let arr2 = element	
            result.push(...arr2)	
          }	

        }
        
        if(isExcel){
          thisObj.downloadFileAs = thisObj.downloadAsExcel
        }else{
          thisObj.downloadFileAs = thisObj.downloadAsPdf
        }
      
        let data = thisObj.grpDisplayMode ? thisObj.processEvents(result) : thisObj.filterWithParams(result)

        thisObj.batchDownload(data, isExcel, thisObj.downloadFileAs, pword,callback2)

      }.bind(this))	
  }

  updateEvents = (action,call) => {	
    if (!this.responseByKey.selectedCamIds || this.responseByKey.selectedCamIds.length === 0) {	
      this.setState({	
        eventList: [],	
        eventCompleteList: [],	
        fetchState: ApiHelper.State.READY	
      })	
      this.eventsByType = {}	
      return	
    }	
    const params = {	
      priority: AppHelper.getGlobalPriotiyValue(),	
      limit: AppConfig.eventQueryLimit,	
    }	
    if (this.responseByKey.fromDateTs && this.responseByKey.toDateTs) {	
      params.fromDateTs = this.responseByKey.fromDateTs	
      params.toDateTs = this.responseByKey.toDateTs	
      params.fetchCount = true

    }	
    params.camId = this.responseByKey.selectedCamIds	
    if (params.camId.includes(AppHelper.getSelectAllOption().key)) {	
      params.camId = [AppHelper.getSelectAllOption().key]	
      params.placeIds = this.responseByKey.selectedPlaceIds	
      params.placeIds = params.placeIds.filter(function (value) {	
        return !value.includes((AppHelper.getSelectAllOption()).key)	
      });	
    }	
    this.setState({	
      // eventList: [],	
      eventListLoading: true,	
      showChart: false,	
    })	
    if (action === 'cam' ||
      action === 'place') {
      this.eventsByType = {}
      this.setState({		
        // eventList: [],		
        eventListLoading: false,		
        showChart: false,		
      })		

      return
    }	

    this.setState({		
      eventList: [],		
    })	

    if (action === 'next') {	
      this.eventsByType = {}	
      if (!this.state.paginate) {	
        return	
      }	
      params.pageNumber = this.state.pageNumber + 1	
    }	
    if (action === 'prev') {	
      this.eventsByType = {}	
      if (this.state.pageNumber === 0) {	
        return	
      }	
      params.pageNumber = this.state.pageNumber - 1	
    }	
    if(this.responseByKey.priorityFilters && this.responseByKey.priorityFilters.length > 0){	
      params['priorityArr'] = JSON.stringify(this.responseByKey.priorityFilters)	
    }	
    const options = {	
      method: 'GET',	
      endPoint: ApiHelper.makeUrlPath(['camevents'], params)	
    }	
    const awsParams = {	
      camIds: params.camId,	
      placeIds: params.placeIds	
    }	
    if (params.fromDateTs && params.toDateTs) {	
      awsParams.fromTs = params.fromDateTs	
      awsParams.toTs = params.toDateTs	
      
    }	
    const thisObj = this	
    const fncs = []	
    fncs.push(function (callback) {	
      ApiHelper.call(options, callback)	
    })	
    if (AppConfig.ES_ENDPOINT) {	
      const awsOptions = {	
        method: 'GET',	
        endPoint: ApiHelper.makeUrlPathAws(['events'], awsParams)	
      }	
      fncs.push(function (callback) {	
        ApiHelper.call(awsOptions, callback)	
      })	
    }	
    async.parallel(	
      fncs,	
      function (err, results) {	
        if (err) {	
          thisObj.setState({	
            fetchState: ApiHelper.State.ERROR,	
            errMsg: Helper.getErrorMsg(err)	
          })	
          return	
        }	
        let modifiedList = this.grpDisplayMode ? this.processEvents(results[0].items) : this.filterWithParams(results[0].items)
        thisObj.setState({	
          pageNumber: results[0].currPageNumber,	
          paginate: results[0].more ? true : false,	
          eventCompleteList: results[0].items,	
          eventList: modifiedList,	
          eventListLoading: false,	
          totalCountOfEvents : results[0].totalCount && results[0].totalCount > 0 ?results[0].totalCount : 0	
        }, () => {	
          if (results.length > 1) {	
            thisObj.setChartFields(results[1])	
          } else {	
            thisObj.setState({	
              showChart: false,	
              fetchState: ApiHelper.State.READY	
            })	
          }	
          if(call){	
          call()	
          }	
        })	
      }.bind(this))	
  }

  setChartFields = (chartData) => {
    this.chartData = []
    let labels = []
    let datasets = []
    let clrs = [ "#fdcce5", "#8bd3c7","#DDFFA9", "#93FFFF","#A3D2FF","#F4ADAE","#FFD993"]

    for (const key in chartData) {
      if (key === 'labels' || key === 'values' || key === 'title') {
        continue
      }
      labels = chartData[key].labels
      datasets = []
      let k = 0
      for (const f in chartData[key].values) {
        datasets.push({
          key: f,
          order: k,
          label: f,
          // borderColor: AppConfig.ES_EVENT_TYPES[f] ? AppConfig.ES_EVENT_TYPES[f].borderColor : AppHelper.getRandomColor(),
          // backgroundColor: AppConfig.ES_EVENT_TYPES[f] ? AppConfig.ES_EVENT_TYPES[f].color : AppHelper.getRandomColor(),
          borderColor:  clrs[Math.floor(Math.random() * clrs.length)],
          backgroundColor:chartData[key].title.includes("Hourly") == false ? clrs[Math.floor(Math.random() * clrs.length)]:"",
          data: chartData[key].values[f].data
        })
        k++
      }
      this.chartData.push({
        title: chartData[key].title,
        labels: labels,
        datasets: datasets
      })
    }
    this.setState({
      showChart: false,
      fetchState: ApiHelper.State.READY
    })
  }

  setChartFieldsOld = (chartData) => {
    const barChartData = {
      labels: [],
      datasets: []
    }

    this.chartData = []
    barChartData.labels = chartData.labels
    barChartData.datasets = []

    let k = 0
    for (const f in chartData.values) {
      barChartData.datasets.push({
        key: f,
        order: k,
        label: f,
        borderColor: AppConfig.ES_EVENT_TYPES[f] ? AppConfig.ES_EVENT_TYPES[f].borderColor : 'orange',
        backgroundColor: AppConfig.ES_EVENT_TYPES[f] ? AppConfig.ES_EVENT_TYPES[f].color : 'orange',
        data: chartData.values[f].data
      })
      k++
    }
    this.chartData.push(barChartData)
    this.setState({
      showChart: false,
      fetchState: ApiHelper.State.READY
    })
  }

  processEvents = (items, val) => {
    items = this.filterWithParams(items)
    this.eventsByGroupId = {}
    const modifiedEventList = []
    for (const item of items) {
      if (!item.groupId) {
        modifiedEventList.push(item)
        continue
      }

      if (this.eventsByGroupId[item.groupId] === undefined) {
        modifiedEventList.push(item)
        this.eventsByGroupId[item.groupId] = []
      } else {
        this.eventsByGroupId[item.groupId].push(item)
      }
    }
  
    this.setEventsByType(modifiedEventList)
    return modifiedEventList
  }

  setEventsByType = (events) => {
    this.eventsByType = {}
    for (const event of events) {
      if (event.type === 'null' || event.type === 'undefined') {
        continue
      }
      if (!event.type) {
        continue
      }
      if (this.eventsByType[event.type]) {
        this.eventsByType[event.type] = this.eventsByType[event.type] + 1
      } else {
        this.eventsByType[event.type] = 1
      }
    }
  }

  setValue = (value, key) => {
    this.responseByKey[key] = value
    this.setState({})
    // this.setState({
    //   eventList: this.grpDisplayMode ? this.processEvents(this.state.eventCompleteList) : this.filterWithParams(this.state.eventCompleteList),
    // })
  }

  timeChangeHandler = (fromDateTs, toDateTs, mode) => {
    this.responseByKey.fromDateTs = fromDateTs
    this.responseByKey.toDateTs = toDateTs
    this.dateMode = mode
    // this.updateEvents('time')
  }

  filterHandler = (tagFilters, priorityFilters, tagValue) => {
    // this.updateEvents("", () => {	
    this.responseByKey.tagFilters = tagFilters
    this.responseByKey.priorityFilters = priorityFilters
    this.tagValue = tagValue
    // this.setState({	
    //   eventList: this.grpDisplayMode ? this.processEvents(this.state.eventCompleteList) : this.filterWithParams(this.state.eventCompleteList),	
    // })	
    // })	
  }	

  camSelectHandler = (camIds) => {
    this.eventsByType = {}	
    this.responseByKey.selectedCamIds = camIds
    this.updateEvents('cam')
  }

  placeSelectHandler = (placeIds) => {
    this.eventsByType = {}	
    this.responseByKey.selectedPlaceIds = placeIds
    this.setFields()
    this.updateEvents('place')
  }

  filterWithParams = (items) => {
    let eventListAfterFilter = []
    if (this.searchText) {
      eventListAfterFilter = SearchHelper.filterItemsBySearchText(this.searchText, items)
    } else {
      eventListAfterFilter = items
    }

    if (this.responseByKey.tagFilters && this.responseByKey.tagFilters.length > 0) {
      eventListAfterFilter = SearchHelper.filterItemsByTag(this.responseByKey.tagFilters, this.tagValue, eventListAfterFilter)
    }

    if (this.responseByKey.priorityFilters && this.responseByKey.priorityFilters.length > 0) {
      eventListAfterFilter = SearchHelper.filterItemsByPriority(this.responseByKey.priorityFilters, eventListAfterFilter)
    }

    this.setEventsByType(eventListAfterFilter)

    return eventListAfterFilter
  }

  getEventCount = () => {
    return 'Page ' + (this.state.pageNumber + 1)
  }

  getPaginationData = () => {
    if (this.state.paginate || this.state.pageNumber !== 0) {
      return (
        <div className={css(Styles.paginationContainer)}>
          <Tooltip title='Previous'>
            <IconButton disabled={this.state.pageNumber < 1} className={css(Styles.refreshIconContainer)}>
              <Icon className={css(Styles.refreshIcon)} color='primary' onClick={() => this.updateEvents('prev')}>
                navigate_before
              </Icon>
            </IconButton>
          </Tooltip>

          <p className={css(Styles.pageNumber)}>{this.getEventCount()}</p>

          <Tooltip title='Next'>
            <IconButton disabled={!this.state.paginate} className={css(Styles.refreshIconContainer)}>
              <Icon className={css(Styles.refreshIcon)} color='primary' onClick={() => this.updateEvents('next')}>
                navigate_next
              </Icon>
            </IconButton>
          </Tooltip>
        </div>
      )
    } else {
      return ''
    }
  }

  showDownloadDialog = () => {
    if (this.state.eventList.length === 0) {
      this.setState({ downloadDialogErrorTitle: 'Empty list', downloadDialogErrorDesc: Helper.getString('noEventsFound') })
    } else if (!this.responseByKey.eventFields || this.responseByKey.eventFields.length === 0) {
      this.setState({ downloadDialogErrorTitle: 'Empty event field', downloadDialogErrorDesc: Helper.getString('noEventFields') })
    }

    this.setState({ downloadDialog: true })
  }

  closeDownloadDialog = (val) => {
    if (val) {
      this.setState({
        downloadDialog: false
      }, () => {
        this.setState({
          downloadDialogErrorTitle: '',
          downloadDialogErrorDesc: ''
        })
      })
    }
  }

  makeFileName = () => {
    let fileName;

    let dateExtension = '-events'

    if (this.responseByKey.fromDateTs && this.responseByKey.toDateTs) {
      dateExtension = '_ ' +
        'from-' +
        moment(this.responseByKey.fromDateTs).format('YYYY') +
        '-' +
        moment(this.responseByKey.fromDateTs).format('MM') +
        '-' +
        moment(this.responseByKey.fromDateTs).format('DD') +
        '-to-' +
        moment(this.responseByKey.toDateTs).format('YYYY') +
        '-' +
        moment(this.responseByKey.toDateTs).format('MM') +
        '-' +
        moment(this.responseByKey.toDateTs).format('DD')
    }

    if (this.responseByKey.selectedCamIds) {
      fileName = 'doc' + '-' + 'cams' + dateExtension;
    } else {
      fileName = 'doc' + '-' + dateExtension;
    }

    return fileName;
  }

  downloadEventsByDate = (pword, isExcel) => {
    this.typeOfFile = isExcel
    this.setState({
      loadReportsPDF: isExcel == false ? true : false,
      loadReportsExcel: isExcel == true ? true : false,
    })
    if (!this.responseByKey.fromDateTs || !this.responseByKey.toDateTs) {
      if (isExcel) {
        this.downloadAsExcel(pword, this.state.eventList)
      } else {
        this.downloadAsPdf(pword, this.state.eventList)
      }
      this.setState({
        loadReportsPDF: false,
        loadReportsExcel: false
      })
    } else {
      this.eventListByDate = []
      this.fetchEventsByDate((val) => {
        if (val) {
          this.setState({
            downloadDialogErrorTitle: 'Info', 
            downloadDialogErrorDesc: Helper.getString(val),
            loadReportsPDF: false,
            loadReportsExcel: false,
            indexOfFile:-1
          })
          return
        }
        this.setState({
          loadReportsPDF: false,
          loadReportsExcel: false,
          downloadDialog: false,
          password: null,
          indexOfFile:-1
        })
      }, isExcel, pword)
    }
  }

  downloadAsExcel = (pword, eventList, call, fileIndex, startingIndex) => {
    let fields = JSON.parse(JSON.stringify(this.responseByKey.eventFields))
    if(fields.indexOf("image") > -1){
      fields.splice(fields.indexOf("image"), 1)
    }
    const fileName = this.makeFileName() + (fileIndex ? "___"+fileIndex : "");
    FileDownloader.prepExcelDownload(
      eventList,
      fields,
      fileName,
      'event_time',
      this.eventsByGroupId,
      this.state.org,
      pword,
      this.state.org[0].type,
      call,
      startingIndex,
      this.state.timezone
    );
    if(!call){
    this.setState({ downloadDialog: false, password: null })
    }
  }

  batchDownload = (totalEventslist, isExcel, download, pword, callback2) => {
    let limitForPdf = AppConfig.PDF_LIMIT
    let limitForExcel = AppConfig.EXCEL_LIMIT
    let chunkSize = 0;
    if (isExcel) {
      chunkSize = limitForExcel
    } else {
      chunkSize = limitForPdf
    }
    const res = [];
    for (let i = 0; i < totalEventslist.length; i += chunkSize) {
      const chunk = totalEventslist.slice(i, i + chunkSize);
      res.push(chunk);
    }

    let fncs = []
    let count = 1;
    for (let index = 0; index < res.length; index++) {
      const element = res[index];
      fncs.push((callback) => {
        download(pword, element, () => {
          count = count + element.length
          return callback(null, {})
        }, index+1, count )

      })
    }

    async.series(fncs, (err, data) => {
      callback2()
    })

  }

  downloadAsPdf = (pword, eventList, call, fileIndex, startingIndex) => {
    let fields = JSON.parse(JSON.stringify(this.responseByKey.eventFields))
    if(fields.indexOf("image") > -1){
      fields.splice(fields.indexOf("image"), 1)
    }
    const fileName = this.makeFileName() + (fileIndex ? "___"+fileIndex : "");
    const columnStyles = {};
    columnStyles['0'] = { cellWidth: 5 };
    fields.forEach((element, index) => {
      columnStyles[index + 1] = { cellWidth: this.pdfAttrColWidth[element] ? this.pdfAttrColWidth[element] : 5 };
    });
    FileDownloader.prepPdfDownload(
      eventList,
      fields,
      fileName,
      columnStyles,
      this.eventsByGroupId,
      this.state.org,
      pword,
      this.state.org[0].type,
      call,
      startingIndex,
      'event_time',
      this.state.timezone
    );
    if (!call) {
      this.setState({ downloadDialog: false, password: null })
    }
  }

  renderTimezone = () => {
    let items = []
    moment.tz.names().forEach(ele => {
     items.push({
        key:ele,
        value:ele,
        name:ele,
        _id : ele
      })
    });
    let options = {options:items}
      return (
        <SingleSelect
          formField={options}
          value={this.state.timezone}
          setValue={(val)=>{
            this.setState({
              timezone : val
            })
          }}
          // singlePickerForm={style.pickerInnerContainer}
        />
      )
  }

  downloadDialogFunction = () => {
    return (
      <Dialog 
        onClose={()=>this.closeDownloadDialog(false)}
        open={this.state.downloadDialog}
        scroll='paper'
        classes={{
          paperWidthSm: css(Styles.dialog),
          scrollPaper: css(Styles.scrollPaper)
        }}>

        <DialogTitle className={css(Styles.dialogTitle)}>
        <div className={css(Styles.dialogTitleContainer)}>
        <p className={css(Styles.dialogTitleContent)}>
            {this.state.downloadDialogErrorTitle ? this.state.downloadDialogErrorTitle : 'Download'} </p>
            <div className={css(Styles.closeIconContainer)} onClick={()=>this.closeDownloadDialog(true)}>
              <img src={require('../../assets/images/close-img.svg')} className={css(Styles.closeIcon)} />
            </div>
          </div>
        
        </DialogTitle>

        {this.state.downloadDialogErrorTitle &&
          <DialogContent className={css(Styles.dialogContent)}>
            <p className={css(Styles.dialogContentData)}>{this.state.downloadDialogErrorDesc}</p>
          </DialogContent>
        }

        {this.state.downloadDialogErrorTitle &&
          <DialogActions className={css(Styles.dialogActions)}>
            <Button
              variant='contained'
              onClick={()=>this.closeDownloadDialog(true)}
              className={css(Styles.dialogActionButton)}>
              OK
            </Button>
          </DialogActions>
        }
          {!this.state.downloadDialogErrorTitle && this.state.indexOfFile>=0 &&  <p style={{textAlign:'center'}}>Processing  {this.state.indexOfFile} / {Math.ceil(this.state.totalCountOfEvents/(this.typeOfFile && this.typeOfFile == true ? AppConfig.EXCEL_LIMIT:AppConfig.PDF_LIMIT))} files</p>}
        {!this.state.downloadDialogErrorTitle &&
          <DialogActions className={css(Styles.dialogActions)}>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <InputLabel classes={{
                root: css(Styles.inputLabelRoot),
                focused: css(Styles.inputLabelFocused)
              }}
              >Encryption password (Optional)</InputLabel>
              <Input type='password'
                onChange={this.pwordHandler}
              />

            </Grid>
            <Grid container
              direction="row"
              // justifyContent="center"
              alignItems="center">
              <InputLabel classes={{
                root: css(Styles.inputLabelRoot),
                focused: css(Styles.inputLabelFocused)
              }}
                
              >Select time zone <p></p></InputLabel>
              {this.renderTimezone()}
            </Grid>
            <Grid style={{flexWrap:"unset"}}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                disabled={this.state.loadReportsExcel || this.state.loadReportsPDF}
                variant='contained'
                onClick={() => this.downloadEventsByDate(this.state.password, true)}
                className={css(Styles.dialogActionButton)}>
                {this.state.loadReportsExcel ? <CircularProgress color='#fff' size={20} /> : ""}
                Download Excel
              </Button>

              <Button
                disabled={this.state.loadReportsPDF || this.state.loadReportsExcel}
                variant='contained'
                onClick={() => this.downloadEventsByDate(this.state.password, false)}
                className={css(Styles.dialogActionButton)}>
                {this.state.loadReportsPDF ? <CircularProgress color='#fff' size={20} /> : ""}
                Download PDF
              </Button>
            </Grid>

          </DialogActions>
        }
      </Dialog>
    );
  }

  pwordHandler = (event) => {
    this.setState({ password: event.target.value });
  }

  setBreadcrumbs = (item) => {
    this.breadcrumbs = [
      {
        title: 'Places',
        to: '/orgs/' + item.orgId + '/places'
      },
      {
        title: 'Reports',
        to: ''
      },
    ]
  }

  printReports = () => {
    this.setState({
      notToPrint: true //TODO Hiding div for printing
    }, () => window.print())

    setTimeout(() => {
      this.setState({
        notToPrint: false
      })
    }, 1000) //TODO Bringing div back on page after performing printing action
  }

  renderFilterResult = () => {
    return (
      <div className={css(Styles.eventContainer)}>
        <div className={css(Styles.eventContainerHeader)}>

          <div className={css(Styles.statsContainer)}>
            <p className={css(Styles.headerContainer)}>Total events  |  {this.state.eventList.length} {this.state.totalCountOfEvents>0?" | Total events in date range  |  "+this.state.totalCountOfEvents + " (without grouping)":""}</p>
            <div className={css(Styles.eventEditContainer, Styles.eventEditContainerStyle)}>
              {Object.keys(this.eventsByType).map((key, index) => {
                return (
                  <div key={index} className={css(Styles.tagContainer)}>
                    <p className={css(Styles.tagKey)}>{key}</p>
                    <p className={css(Styles.tagValueVariant)}>{this.eventsByType[key]}</p>
                  </div>
                )
              })}
            </div>
          </div>

          <div className={css(Styles.downloadContainer)}>

            {this.getPaginationData()}

            {/* {AppConfig.ENABLE_REPORT_DOWNLOAD &&
              <Tooltip title={Helper.getString('downloadEventsWithImages')}>
                <Icon className={css(Styles.downloadIcon)} onClick={() => this.printReports()}>
                  save
                </Icon>
              </Tooltip>
            } */}

            {AppConfig.ENABLE_REPORT_DOWNLOAD &&
              <Tooltip title={Helper.getString('downloadEventsWithNoImages')}>
                <div className={css(Styles.downloadImage)} onClick={() => this.showDownloadDialog()}>
                  <img src={require('../../assets/images/download-img1.svg')} />
                </div>
              </Tooltip>
            }

          </div>
        </div>

        {this.eventTable()}

      </div>
    )
  }


  renderFilters = () => {
    return (
      <div className={css(Styles.formOuterContainer)}>
        <div className={css(Styles.formInnerContainer)}>
          <CheckboxComponent
            orgType={ this.state.org && this.state.org[0] ? this.state.org[0].type: ''}
            inputCss={{ flex: 1 , margin:'0px 8px', minHeight:60}}
            formField={appData.reportFields.eventFields}
            value={this.responseByKey[appData.reportFields.eventFields.key] ? this.responseByKey[appData.reportFields.eventFields.key] : []}
            setValue={this.setValue.bind(this)}
            disabled={false} />
          <CheckboxComponent
            inputCss={{ flex: 1, margin:'0px 8px', minHeight:60 }}
            formField={appData.reportFields.eventImages}
            value={this.responseByKey[appData.reportFields.eventImages.key] ? this.responseByKey[appData.reportFields.eventImages.key] : []}
            setValue={this.setValue.bind(this)}
            disabled={false} />
        </div>
      </div>
    )
  }

  getPlaceId = (item) => {
    for (const o of this.state.org) {
      for (const c of o.cams) {
        if (item.camId === c._id) {
          return o._id
        }
      }
    }
  }

  onEventClick = (item) => {
    this.placeId = this.getPlaceId(item)
    this.setState({
      selectedEventId: item._id,
      showEvent: true
    })
  }

  eventTable = () => {
    if (this.state.eventListLoading) {
      return (
        <div className={css(Styles.progressContainer)}>
          <CircularProgress color='#000' size={30} className={css(Styles.progress)} />
        </div>
      )
    }

    return (
      <TableContainer style={{ maxHeight: 1800 }}>
      <Table className={css(Styles.tableOuterContainer)}>
        <TableHead className={css(Styles.tableHead)}>
          <TableRow className={css(Styles.tableRowHeader)}>
            <TableCell
              align='left'
              className={css(Styles.tableHeader)}>
              <p>SL.No</p>
            </TableCell>

            {(!this.responseByKey.eventFields || this.responseByKey.eventFields.length === 0) ?
              adminData.eventFieldsDefault.map((events, i) => {
                return (
                  <TableCell key={i}
                    align='left'
                    className={css(Styles.tableHeader)}>
                    {this.state.org && this.state.org.length > 0  && this.state.org[0].type == "wfhTeams" &&  events.name_wfh ? events.name_wfh :events.name}
                  </TableCell>
                )
              })
              :
              adminData.eventFields.map(field => {
                return (
                  this.responseByKey.eventFields.map(key => {
                    return (
                      key === field.key &&
                      <TableCell
                        align='left'
                        className={css(Styles.tableHeader)}>
                         {this.state.org && this.state.org.length > 0 && this.state.org[0].type == "wfhTeams" &&  field.name_wfh ? field.name_wfh :field.name}
                      </TableCell>
                    )
                  }))
              })
            }

          </TableRow>
        </TableHead>

        <TableBody>
          {this.responseByKey.eventFields.length === 0 ?
            this.state.eventList.map((item, index) => {
              return (
                <TableRow key={index} onClick={() => this.onEventClick(item)} className={css(Styles.tableRow)}>
                  <TableCell align='left' className={css(Styles.tableItem)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p>{index + 1 + (this.state.pageNumber * AppConfig.eventQueryLimit)}</p>
                    </div>
                  </TableCell> 
                  <TableCell align='left' className={css(Styles.tableItem)}>{this.renderPlaceName(item)}</TableCell>
                  <TableCell align='left' className={css(Styles.tableItem)}>{this.renderCamName(item)}</TableCell>
                  <TableCell align='left' className={css(Styles.tableItem)}>{item.name}</TableCell>
                  <TableCell align='left' className={css(Styles.tableItem)}>{AppHelper.getPriorityStr(item.priority)}</TableCell>
                  <TableCell >
                    {this.responseByKey.eventImages.length === 0 ?
                      <div className={css(Styles.eventListImageContainer)}>
                        <img
                          key={item.image}
                          src={item.image}
                          className={css(Styles.eventImg)}></img>
                      </div>
                      :
                      this.responseByKey.eventImages.map(key => {
                        return (
                          this.eventImage(item, key)
                        )
                      })
                    }
                  </TableCell>
                  {/* <TableCell align='left' className={css(Styles.tableItem)}>{this.eventsByGroupId[item.groupId] ? (this.eventsByGroupId[item.groupId].length + 1) : 1}</TableCell> */}
                  {/* <TableCell align='left' className={css(Styles.tableItem, Styles.prewrap)}>{this.renderComment(item)}</TableCell> */}
                </TableRow>
              )
            })
            :
            this.state.eventList.map((item, index) => {
              return (
                <TableRow onClick={() => this.onEventClick(item)} className={css(Styles.tableRow)}>
                  <TableCell align='left' className={css(Styles.tableItem)}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p>{index + 1 + (this.state.pageNumber * AppConfig.eventQueryLimit)}</p>
                    </div>
                  </TableCell>

                  {adminData.eventFields.map(field => {
                    return (
                      this.responseByKey.eventFields.map(key => {
                        return (
                          field.key === key &&
                          this.evenSelectedfunction(item, key)
                        )
                      })
                    )
                  })}
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      </TableContainer>
    )
  }

  eventImage = (item, key) => {
    const imgArr = [];
    if (key === 'primaryImage') {
      imgArr.push(
        <div className={css(Styles.eventListImageContainer)}>
          <img
            key={item.image}
            src={item.image}
            className={css(Styles.eventImg, Styles.eventListImageConatinerSecondary)}></img>
        </div>
      )
    } else if (key === 'secondaryImage' &&
      item.additionalImages &&
      item.additionalImages.length > 0) {
      imgArr.push(
        <div className={css(Styles.eventListImageContainer)}>
          <img
            key={item.additionalImages[0]}
            src={item.additionalImages[0]}
            className={css(Styles.eventImg)}></img>
        </div>
      )
    }
    return imgArr
  }

  renderTypes = (type) => {
    if (type === 'null' || type === 'undefined') {
      return null
    }
    return type
  }

  renderTags = (item) => {
    if (!item.tags) {
      return ''
    }
    const tagKeys = Object.keys(item.tags)
    return (
      <div className={css(Styles.tagsContainer)}>
        {tagKeys.map((tagK, index) => {
          return (
            <p className={css(Styles.tagValue)}>{tagK} : {item.tags[tagK]}</p>
          )
        })}
      </div>
    )
  }

  evenSelectedfunction = (item, key) => {
    if (key === 'camId') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {item.camId}
        </TableCell>
      )
    }
    else if (key === 'event_time') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {moment(item.event_time).format('MMM DD, YYYY, h:mm:ss A')}
        </TableCell>
      )
    }
    else if (key === 'name') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {item.name}
        </TableCell>
      )
    }
    else if (key === 'priority') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {AppHelper.getPriorityStr(item.priority)}
        </TableCell>
      )
    }
    else if (key === 'priorityReported') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {AppHelper.getPriorityStr(item.priorityReported)}
        </TableCell>
      )
    }
    else if (key === 'type') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {this.renderTypes(item.type)}
        </TableCell>
      )
    } else if (key === 'tags') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {this.renderTags(item)}
        </TableCell>
      )
    } else if (key === 'image') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {this.responseByKey.eventImages.length === 0 ?
            <div className={css(Styles.eventListImageContainer)}>
              <img
                key={item.image}
                src={item.image}
                className={css(Styles.eventImg)}></img>
            </div>
            :
            this.responseByKey.eventImages.map(key => {
              return (
                this.eventImage(item, key)
              )
            })
          }
        </TableCell>
      )
    } else if (key === 'grouped_events') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {this.eventsByGroupId[item.groupId] ? (this.eventsByGroupId[item.groupId].length + 1) : 1}
        </TableCell>
      )
    } else if (key === 'placeId') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {this.renderPlaceid(item)}
        </TableCell>
      )
    } else if (key === 'place_name') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {this.renderPlaceName(item)}
        </TableCell>
      )
    } else if (key === 'cam_name') {
      return (
        <TableCell align='left' className={css(Styles.tableItem)}>
          {this.renderCamName(item)}
        </TableCell>
      )
    } else if (key === 'comments') {
      return (
        <TableCell align='left' className={css(Styles.tableItem, Styles.prewrap)}>
          {this.renderComment(item)}
        </TableCell>
      )
    }
  }

  renderPlaceid = (item) => {
    for (const o of this.state.org) {
      for (const c of o.cams) {
        if (c._id === item.camId) {
          return o._id
        }
      }
    }
  }

  renderComment = (item) => {
    var text = ""
    for (var i = 0; i < item.reviews.length; i++) {
      let element = item.reviews[i];
      var nameOfmanager = element.name ? element.name : element.email
      var commentByManager = element.comment ? element.comment : "no comment done"

      text += `${nameOfmanager.toUpperCase()}  :  ${commentByManager}\n	
      `
    }
    return text
  }

  renderPlaceName = (item) => {
    for (const o of this.state.org) {
      for (const c of o.cams) {
        if (c._id === item.camId) {
          return o.name
        }
      }
    }
  }

  renderComment = (item) => {
    var text =  ""
    for(var i = 0;i<item.reviews.length;i++){
      let element  = item.reviews[i];
      var nameOfmanager = element.name? element.name:element.email
      var commentByManager = element.comment? element.comment:"no comment done"
      
      text += `${nameOfmanager.toUpperCase()}  :  ${commentByManager}\n
      `
    }
    return text
  }

  renderCamName = (item) => {
    for (const o of this.state.org) {
      for (const c of o.cams) {
        if (c._id === item.camId) {
          return c.name
        }
      }
    }
  }

  onGroupedDisplayChange = (value) => {
    this.grpDisplayMode = value
  }

  handleShowEvent = (value) => {
    this.setState({
      showEvent: value
    })
  }

  renderEvent = () => {
    return (
      <div className={css(Styles.eventContainer)}>
        <div className={css(Styles.eventContainerHeader, Styles.eventContainerHeaderSpec)}>
          <Event
            orgLevel={true}
            handleShowEvent={this.handleShowEvent}
            eventId={this.state.selectedEventId} />
        </div>

      </div>
    )
  }

  showChartHandler = (value) => {
    this.setState({ showChart: value })
  }

  readyView = () => {
    return (
      <div className={css(CommonStyles.outerMainContainer)}>

        <div hidden={this.state.notToPrint}>
          <EventActionHeaderbar
            showApplyFilters={true}
            applyFilters={this.applyFilters}
            camPicker={appData.reportFields.selectCam}
            placePicker={this.placePicker}
            priorityFilters={this.responseByKey.priorityFilters}
            tagFilters={this.responseByKey.tagFilters}
            filterTagValue={this.responseByKey.filterTagValue}
            selectedPlaceIds={this.responseByKey.selectedPlaceIds}
            breadcrumbs={this.breadcrumbs}
            timeChangeHandler={this.timeChangeHandler}
            camSelectHandler={this.camSelectHandler}
            placeSelectHandler={this.placeSelectHandler}
            filterHandler={this.filterHandler}
            place={this.state.place}
            showSummaryButton={false}
            showChart={this.state.showChart}
            showChartHandler={this.showChartHandler}
            showChartButton={AppConfig.ES_ENDPOINT ? true : false}
            selectedCamIds={this.responseByKey.selectedCamIds}
            toDateTs={this.responseByKey.toDateTs}
            fromDateTs={this.responseByKey.fromDateTs}
          />
        </div>

        <div className={css(CommonStyles.innerMainContainer)}>
          <div className={css(Styles.innerContainer)}>
            <div hidden={this.state.notToPrint} >
              {this.renderFilters()}
            </div>

            {this.state.showChart &&
              <div>
                <ChartComponent
                  data={this.chartData}
                  showChart={this.state.showChart}
                  showChartHandler={this.showChartHandler}
                />
              </div>
            }

            {!this.state.showEvent &&
              this.renderFilterResult()
            }

            {this.state.showEvent &&
              this.renderEvent()
            }

          </div>
        </div>

        {this.state.downloadDialog &&
          this.downloadDialogFunction()
        }

      </div>
    )
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      return UiHelper.errorView(this);
    } else {
      return this.readyView();
    }
  }
}


const Styles = StyleSheet.create({
  tableHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    flex: 1,
    minWidth: 'unset',
    // color: AppTheme.white,
    color:AppTheme.primaryBase,
    padding:'0px 16px'
  },
  tableRowHeader: {
    // backgroundColor: '#202633',
    backgroundColor:"#fff",

    borderRadius: '4px 4px 0 0',
  },
  tableRow: {
    // backgroundColor: '#202633',
    backgroundColor:"#fff",

    borderRadius: '4px 4px 0 0',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: "#fff",
    }
  },
  tableItem: {
    // color: AppTheme.white,
    color:"#000",
    fontWeight: 'bolder'
  },
  tableOuterContainer: {
  },
  tableHead: {
    marginTop: 10,
    backgroundColor: AppTheme.primaryBackground,
  },
  eventListImageContainer: {
    width: 100,
    display: 'flex',
    marginBottom: 5,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  eventListImageConatinerSecondary: {
    marginTop: 5
  },
  eventImg: {
    width: '100%'
  },
  pageNumber: {
    opacity: 0.7,
    color: AppTheme.white,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      fontSize: 15,
    }
  },
  refreshIconContainer: {
    padding: '0px 12px',
    ':hover': {
      backgroundColor: AppTheme.primaryBackground,
    },
    '@media (max-width: 767px)': {
      padding: '0px 5px',
    }
  },
  refreshIcon: {
    borderRadius: '50%',
    cursor: 'pointer',
    opacity: 0.5,
    color: AppTheme.white,
    ':hover': {
      backgroundColor: '#1D2331',
    }
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  downloadImage: {
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5,
    '@media (max-width: 767px)': {
      paddingLeft: 5,
      paddingRight: 5,
    },
    borderRadius: '4px',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
  },
  formOuterContainer: {
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: AppTheme.primaryBackground,
    border:"1px solid",
    padding: 20,
    paddingTop: 0,
    paddingBottom: 0
  },
  innerContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 40,
    marginBottom: 50,
  },
  formInnerContainer: {
    display: 'flex',
    flex: 1,
  },
  eventContainer: {
    display: 'flex',
    flexDirection: 'column',
    // backgroundColor: AppTheme.secondaryColor,
    marginTop: 10,
    overflowX: 'scroll'
  },
  eventContainerHeader: {
    display: 'flex',
    alignItems: 'center',
    // backgroundColor: AppTheme.primaryBackground,
    padding: 10,
    borderRadius: '4px 4px 0 0',
  },
  eventContainerHeaderSpec: {
    // backgroundColor: AppTheme.secondaryColor,
    padding: 0,
  },
  downloadContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor:AppTheme.primaryBase,
    borderRadius: '4px',
  },
  dialogTitle: {
    padding: 20,
    backgroundColor: AppTheme.white
  },
  dialogTitleContainer: {
    display: 'flex',
    justifyContent:"space-between"
  },
  dialogTitleContent: {
    fontWeight: 600,
    fontSize: 16,
    margin: 0,
    color: AppTheme.primaryBackground,
  },
  dialogContent: {
    padding: 0,
    color:"#000",
    // backgroundColor: AppTheme.primaryBackground,
    marginBottom: -1
  },
  dialogContentData: {
    margin: 0,
    padding: '15px 20px 0px 20px',
    fontWeight: 600,
    opacity: 0.6,
    // color: AppTheme.white,
  },
  dialogActions: {
    padding: 20,
    display: 'flex',
    justifyContent: 'flex-start',
    // backgroundColor: AppTheme.primaryBackground,
  },
  dialogActionButton: {
    fontWeight: 'bolder',
    margin: 0,
    marginRight: 10,
    fontSize: 15,
    textTransform: 'unset',
    backgroundColor: ColourPalette.color3,
    color: AppTheme.white,
    padding: '5px'
  },
  headerContainer: {
    fontSize: 18,
    fontWeight: 'bold',
    flex: 1,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    // color: AppTheme.white,
    color: "#000",
    '@media (max-width: 767px)': {
      fontSize: 16,
    }
  },
  downloadIcon: {
    paddingLeft: 10,
    paddingRight: 10,
    cursor: 'pointer',
    // color: ColourPalette.color1,
    color:"rgb(255, 255, 255) !important",
    '@media (max-width: 767px)': {
      paddingLeft: 5,
      paddingRight: 5,
    }
  },
  dialog: {
    minWidth: 500,
  },
  progressContainer: {
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  progress: {
    color: ColourPalette.color1
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: '#000',
    fontWeight: 'bolder'
  },
  tagValue: {
    margin: 0,
    marginBottom: 5
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  tagContainer: {
    border: '1px solid #566380',
    marginTop: 5,
    marginRight: 15,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tagContainerDetail: {
    border: '1px solid #6c6c6c',
    borderRadius: 5,
  },
  tagKey: {
    // backgroundColor: ColourPalette.color1,
    margin: 0,
    color: AppTheme.primaryBackground,
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 5,
    borderRadius: '4px 0px 0px 4px',
    cursor: 'default'
  },
  tagValueVariant: {
    margin: 0,
    // color: '#C7C9CB',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: 4,
    cursor: 'default'
  },
  eventEditContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  eventEditContainerStyle: {
    marginTop: 0,
    flex: 1,
  },
  menuHeaderRoleConatiner: {
    cursor: 'pointer',
    backgroundColor: ColourPalette.color1,
    marginRight: 5,
    marginLeft: 5,
    borderRadius: 5,
    padding: 4,
    display: 'flex',
    maxWidth: 60,
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width: 767px)': {
      marginTop: 5
    }
  },
  menuHeaderRole: {
    margin: 0,
    color: AppTheme.primaryBackground,
    fontSize: 13,
    fontWeight: 'bold'
  },
  inputLabelRoot: {
    // color: AppTheme.white
  },
  inputLabelFocused: {
    color: AppTheme.white
  },
  prewrap: {
    whiteSpace: "pre-line"
  }
})