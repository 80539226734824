/* eslint-disable */ 
import React, { Component } from 'react';
import { StyleSheet, css } from 'aphrodite';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Controller } from '../../common/Controller';
import { UiLoginHelper } from '../../common/helpers/UiLoginHelper';
import { UsernameInput, PasswordInput, NumericOTPInput } from '../../common/components/TextInputs';
import { AppConfig } from '../../AppConfig';
import { AppTheme } from '../../styles/AppTheme'
import { UiHelper } from '../../common/helpers/UiHelper';
import Link from '@material-ui/core/Link';

// import { controllers } from 'chart.js';
// return UiHelper.componentLoadingView();
const isVapt = true
let  newTab 
let uuid = ""
export class Login extends Component {
  constructor(props) {
    super(props);
    this.userNameType = UiLoginHelper.UserNameType.EMAIL;

    let _currentPage = null ;
    if (AppConfig.allowLocal){
      _currentPage = UiLoginHelper.Page.LOGIN;
    } else if (AppConfig.SSO && AppConfig.SSO.IDP_PROVIDER == "Google") {
      _currentPage = UiLoginHelper.Page.GOOGLE_SIGN_IN;
    } else if (AppConfig.SSO && AppConfig.SSO.IDP_PROVIDER == "Azure") {
      _currentPage = UiLoginHelper.Page.AZURE_SIGN_IN;
    } else {
      _currentPage = isVapt ? UiLoginHelper.Page.LOGIN : UiLoginHelper.Page.LOOKUP
    }

    this.isIframe = false
    if (window.self !== window.top) {
      console.log("The URL is opened within an iframe.");
      this.isIframe = true
    } else {
      console.log("The URL is opened in the main browser window.");
      this.isIframe = false
    }

    // Original code
    // let _currentPage = AppConfig.allowLocal ? UiLoginHelper.Page.LOGIN : (AppConfig.SSO ? UiLoginHelper.Page.AZURE_SIGN_IN : UiLoginHelper.Page.LOOKUP),

    this.state = {
      currentPage: _currentPage,
      loadingAnimation: false,
      message: '',
      userName: '',
      pword: '',
      verificationCode: '',
      secretKeysPressed: [],
      cmView:true,
      logoName:AppConfig.logoName,
      productName:AppConfig.product,
      loadNewWindow:false
    };
    
    this.username = Controller.get() && Controller.get().history && Controller.get().history.location &&Controller.get().history.location.state && Controller.get().history.location.state.state &&  Controller.get().history.location.state.state.email ?  Controller.get().history.location.state.state.email: ""
    this.type = Controller.get() && Controller.get().history && Controller.get().history.location &&Controller.get().history.location.state && Controller.get().history.location.state.state &&  Controller.get().history.location.state.state.type ?  Controller.get().history.location.state.state.type: ""

    this.loginHelper = new UiLoginHelper(this, {
      autoSignUp: this.username ? true:false,
      autoLogin: true,
      userNameType: this.userNameType,
      jsonBody: this.props.jsonBody ? this.props.jsonBody : {},
      onSuccessLogin: this.onSuccessLogin.bind(this)
    });
  }

  componentDidMount() {
    let username = Controller.get() && Controller.get().history && Controller.get().history.location && Controller.get().history.location.state && Controller.get().history.location.state.state && Controller.get().history.location.state.state.email ? Controller.get().history.location.state.state.email : ""
    let pword = Controller.get() && Controller.get().history && Controller.get().history.location && Controller.get().history.location.state && Controller.get().history.location.state.state && Controller.get().history.location.state.state.pword ? Controller.get().history.location.state.state.pword : ""
    let type = Controller.get() && Controller.get().history && Controller.get().history.location && Controller.get().history.location.state && Controller.get().history.location.state.state && Controller.get().history.location.state.state.type ? Controller.get().history.location.state.state.type : ""

    if (type === "cameramanager") {
      this.setState({
        userName: username,
        pword: pword
      }, () => {
        this.loginHelper.onSubmitLookup(() => {
          this.setState({
            pword: pword
          }, () => {
            this.loginHelper.onSubmitLogin(() => {
            })
          })

        }, false, true)
      })
    }

    if (sessionStorage.getItem("uuidv4")) {
      uuid = sessionStorage.getItem("uuidv4")
      // window.location.replace(AppConfig.vmsLoginURL + AppConfig.eevmsLoginURL)
   }
     window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event) => {
    if (event.key === 'u' || event.key === 'v') {
      this.setState((prevState) => {
        const { secretKeysPressed } = prevState;
        if (!secretKeysPressed.includes(event.key)) {
          return { secretKeysPressed: [...secretKeysPressed, event.key] };
        }
        return null;
      });
    }
    const { secretKeysPressed } = this.state;
    const areSecretKeysPressed = secretKeysPressed.includes('u') && secretKeysPressed.includes('v');
    if(areSecretKeysPressed){
      this.setState({
        cmView : false
      })
    }
  };


  onSuccessLogin() {
    Controller.get().userMgr().setGuestUser(false);
    if (this.props.onSuccessLogin) {
      this.props.onSuccessLogin()
      return
    }
    Controller.get().userMgr().navigateUserToHome();
  }

  render() {
    if(this.type=="cameramanager"){
      return UiHelper.componentLoadingView();
    }
    return (
      <>   
  {this.state.cmView && this.state.logoName!="alibi" && <nav className={css(Styles.navbar)}>
        <div>

          <a href="https://eagleeyenetworks.com/">
            <img
            className={css(Styles.cmLogoImg)}
            src="https://rest.cameramanager.com/auth/assets/een-logo.png"
            alt="EEN logo" />
          </a>

        </div>
      </nav>}
        <div className={css(Styles.container, this.state.cmView ? Styles.bgNone : '')}>

          <div className={css([Styles.loginPanel, this.props.loginPanelStyle, this.state.cmView ? Styles.loginPanelCm : ''])}>


            {<div className={css(Styles.logoImageContainer)}>
              <img
                // src={this.state.cmView ? require('../../assets/images/'+ this.state.logoName +'-logo.png') : require('../../assets/images/logo.png')}
                src={require('../../assets/images/logo3.png')}

                alt='Logo'
                className={css(Styles.logoImage)} />
            </div>}

            <div className={css(Styles.loginMessageContainer)}>
              {this.state.message.length > 0 &&
                <p className={css(Styles.loginMessage)}>{this.state.message}</p>
              }
            </div>

            {this.state.currentPage === UiLoginHelper.Page.AZURE_SIGN_IN &&
              this.signUpWithAzure()
            }
            {this.state.currentPage === UiLoginHelper.Page.GOOGLE_SIGN_IN &&
              this.signUpWithGoogle()
            }
            {this.state.currentPage === UiLoginHelper.Page.LOOKUP &&
              this.welcomeView()
            }
            {this.state.currentPage === UiLoginHelper.Page.SIGNUP &&
              this.signUpView()
            }
            {this.state.currentPage === UiLoginHelper.Page.SIGNUP_OTP &&
              this.signUpOTPView()
            }
            {this.state.currentPage === UiLoginHelper.Page.LOGIN && !this.state.cmView &&
              this.loginView()
            }
            {this.state.currentPage === UiLoginHelper.Page.LOGIN && this.state.cmView &&
              this.loginCmView()
            }
            {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD &&
              this.resetPasswordView()
            }
            {this.state.currentPage === UiLoginHelper.Page.RESET_PASSWORD_OTP &&
              this.resetPasswordOTPView()
            }
          </div>
        </div>
        </>
    );
  }

  signUpWithGoogle() {
    return (
      <div>

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLoginIdp.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            SIGN IN WITH GOOGLE
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
        {this.props.showTerms &&
          <div className={css(Styles.termsContainer)}>
            <span>By continuing, you agree to our </span><a href={AppConfig.TERMS}>terms</a>
          </div>
        }
      </div>
    )
  }

  signUpWithAzure() {
    return (
      <div>

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLoginIdp.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            SIGN IN WITH AZURE
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
        {this.props.showTerms &&
          <div className={css(Styles.termsContainer)}>
            <span>By continuing, you agree to our </span><a href={AppConfig.TERMS}>terms</a>
          </div>
        }
      </div>
    )
  }

  welcomeView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLookup()
            }
          }}
        />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLookup.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Continue
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
        { 
          <Link
          style={{marginTop:"0.5em"}}
          component="button"
          variant="body2"
          onClick={() => {
          window.location.replace(AppConfig.cmLoginURL+AppConfig.loginURL)
          }}
        >
          Sign in With {this.state.productName}
        </Link>
        }
        {this.props.showTerms &&
          <div className={css(Styles.termsContainer)}>
            <span>By continuing, you agree to our </span><a href={AppConfig.TERMS}>terms</a>
          </div>
        }
      </div>
    )
  }

  signUpView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          placeholder='Set a new password'
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUp()
            }
          }}
        />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitSignUp.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Signup
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
        <a className={css(Styles.loginLink)} onClick={this.onLoginClicked.bind(this)}><p>Already have an account ? Login</p></a>
      </div>
    )
  }

  signUpOTPView() {
    return (
      <div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          onChange={(event) => this.setState({ verificationCode: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitSignUpConfirm()
            }
          }} />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitSignUpConfirm.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Submit
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
      </div>
    )
  }


  openInNewWindow(){
    // this.startPullingCode(uuid)
    this.setState({
      loadNewWindow:true
    },()=>{

    const windowName = 'Eagle Eye Invent'; // Name of the new window
    const width = 800;
    const height = 600;
    
    // Calculate the position for bottom left
    const left =  window.screen.width - width;
    const top = window.screen.height - height;

    const features = `width=${width},height=${height},resizable,scrollbars,left=${left},top=${top}`;

    // window.open(url, windowName, features);
    window.open("https://sandbox.remotifai.com/login?uuid="+uuid,windowName, features)
  }
    )
  }

  loginView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })} />

        <PasswordInput
          id='pword'
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitLogin()
            }
          }} />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitLogin.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Login
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>

        {!AppConfig.allowLocal &&
          <div>
            <a className={css(Styles.loginLink)} onClick={this.onResetPasswordClicked.bind(this)}><p>Reset password? Get OTP</p></a>
            <a className={css(Styles.loginLink)} onClick={this.onSignupClicked.bind(this)}><p>New User ? Sign Up</p></a>
            <a className={css(Styles.loginLink)} onClick={() => {
              newTab = window.open(AppConfig.vmsLoginURL + AppConfig.eevmsLoginURL, '_blank')
            }}><p>Sign in with Eagle Eye Cloud VMS</p></a>
            <a className={css(Styles.loginLink)} onClick={() => {
              window.location.replace(AppConfig.cmLoginURL + AppConfig.loginURL)
            }}><p>Sign in with Eagle Eye CameraManager</p></a>

          </div>
        }

      </div>
    );
  }

  loginCmView() {
    return (
      <div>
        {!AppConfig.allowLocal  && !this.isIframe && 
          <div>
            <a className={css(Styles.loginLinkCm)} onClick={() => {
                  window.location.replace(AppConfig.vmsLoginURL + AppConfig.eevmsLoginURL)
            }}><p>Sign in with Eagle Eye Cloud VMS</p></a>
          </div>
        }
         {!AppConfig.allowLocal && this.isIframe && !this.state.loadNewWindow &&
          <div>
             <a className={css(Styles.loginLinkCm)} onClick={() => this.openInNewWindow()}><p>Sign In</p></a>
          </div>
        }
       {!AppConfig.allowLocal && this.isIframe && this.state.loadNewWindow &&
          <div>
             <CircularProgress color={AppTheme.primaryBackground} size={20} className={css(Styles.marginLeft)} />
             {/* <a className={css(Styles.loginLinkCm)} onClick={() => this.openInNewWindow()}><p>Retry Sign In</p></a> */}
            <p>Please log in to the new window.</p>

          </div>
        }
        {!AppConfig.allowLocal  && !this.isIframe &&
          <div>
            <a className={css(Styles.loginLinkCm)} onClick={() => {
              window.location.replace(AppConfig.cmLoginURL + AppConfig.loginURL)
            }}><p>Sign in with Eagle Eye CameraManager</p></a>
          </div>
        }

      </div>
    );
  }

  resetPasswordView() {
    return (
      <div>
        <UsernameInput
          id='userName'
          value={this.state.userName}
          userNameType={this.userNameType}
          onChange={(event) => this.setState({ userName: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitResetPassword()
            }
          }} />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitResetPassword.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Get OTP
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
      </div>
    )
  }

  resetPasswordOTPView() {
    return (
      <div>
        <NumericOTPInput
          id='verificationCode'
          value={this.state.verificationCode}
          onChange={(event) => this.setState({ verificationCode: event.target.value })} />

        <PasswordInput
          id='pword'
          value={this.state.pword}
          onChange={(event) => this.setState({ pword: event.target.value })}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              this.loginHelper.onSubmitConfirmResetPassword()
            }
          }} />

        <div className={css(Styles.paddingTop20px)}>
          <Button fullWidth margin='normal'
            variant={this.state.loadingAnimation ? 'outlined' : 'contained'}
            color='primary'
            disabled={this.state.loadingAnimation}
            onClick={this.loginHelper.onSubmitConfirmResetPassword.bind(this.loginHelper)}
            className={(css(Styles.buttonStyle))}
          >
            Submit
            {this.state.loadingAnimation && <CircularProgress color={AppTheme.buttonTextColor} size={20} className={css(Styles.marginLeft)} />}
          </Button>
        </div>
      </div>
    )
  }

  onStartoverClicked() {
    this.setState({
      message: '',
      userName: '',
      pword: '',
      verificationCode: ''
    })
    this.loginHelper.toPage(UiLoginHelper.Page.LOOKUP);
  }

  onLoginClicked() {
    this.setState({
      message: '',
      userName: '',
      pword: '',
      verificationCode: ''
    })
    this.loginHelper.toPage(UiLoginHelper.Page.LOGIN);
  }

  onSignupClicked() {
    this.setState({
      message: '',
      userName: '',
      pword: '',
      verificationCode: ''
    })
    this.loginHelper.toPage(UiLoginHelper.Page.SIGNUP);
  }

  onResetPasswordClicked() {
    this.loginHelper.toPage(UiLoginHelper.Page.RESET_PASSWORD);
  }
}

const Styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    backgroundSize: 'cover',
    minHeight: '100vh',
    backgroundImage: `url(${require('../../assets/images/login-bg.jpg')})`,
  },
  bgNone:{
    backgroundImage: `none`,
  },
  loginPanel: {
    borderRadius: 8,
    backgroundColor: '#262930c9',
    textAlign: 'center',
    width: 400,
    padding: 20,
    '@media (max-width: 767px)': {
      width: 280,
      padding: 10,
      margin: 5,
    },
    '@media (max-width: 320px)': {
      width: 240,
      padding: 10,
      margin: 5
    }
  },
  loginPanelCm:{
    boxShadow: 'rgb(204, 204, 204) 0px 0px 15px 0px',
    borderRadius: '4px',
    backgroundColor: '#fff !important',
  },
  loginMessageContainer: {
    margin: 'auto'
  },
  loginMessage: {
    marginTop: 10,
    fontSize: 14,
    color: 'white'
  },
  loginLink: {
    marginTop: 10,
    cursor: 'pointer',
    color: 'white',
    fontSize: 14
  },
  loginLinkCm: {
    marginTop: 10,
    cursor: 'pointer',
    color: AppTheme.primaryBase+' !important',
    fontSize: 14
  },
  paddingTop20px: {
    paddingTop: 20
  },
  marginLeft: {
    marginLeft: 10
  },
  termsContainer: {
    marginTop: 10,
    fontSize: 14,
    color: 'white'
  },
  buttonStyle: {
    boxShadow: 'none',
    backgroundColor: AppTheme.buttonBg,
    color: AppTheme.buttonTextColor,
    borderRadius: AppTheme.pagePadding,
    textAlign: 'center',
    letterSpacing: 0,
    textTransform: 'uppercase',
    opacity: 1,
  },
  buttonStyleCm:{
    backgroundColor:AppTheme.primaryBase+" !important",
    color: '#fff'
  },
  logoImageContainer: {
    width: 330,
    margin: 'auto',
    borderRadius: 5,
  },
  logoImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: 5,
  },
  navbar:{
    position:"absolute",
    left: '0.9rem !important',
    top: '0.5rem',
  },
  cmLogoImg:{
    maxHeight:'2.8rem'
  }
})
