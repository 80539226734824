/* eslint-disable */ 
import React, { Component } from 'react';
import { css } from 'aphrodite';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ApiHelper } from '../../common/helpers/ApiHelper';
import { Helper } from '../../common/helpers/Helper';
import { Controller } from '../../common/Controller';
import { UiHelper } from '../../common/helpers/UiHelper';
import { AppHelper } from '../../helpers/AppHelper.js';
import { SearchHelper } from '../../helpers/SearchHelper';
import { EventActionHeaderbar } from './EventActionHeaderBar';
import { EventList } from './EventList';
import { StatsComponent } from './StatsComponent';
import { EventDetails } from './EventDetails';
import { EventEditor } from './EventEditor';
import { adminData } from '../../data/AdminData.js'
import { AppConfig } from '../../AppConfig';
import { CommonStyles } from '../../styles/Styles';
import { Styles } from './Styles';
import async from 'async';

// import alertSound from '../../assets/audio/alert_tone.mp3'
// var faceMap = {}

const queryString = require('query-string');
Array.prototype.shiftRight = function(params) {
  params.reverse();
  params.forEach(item => {
      for (var i = this.length - 1; i >= 0; i--) {
          if (i === 0)
             this[0] = item
          else
             this[i] = this[i - 1];
      }
  })
}
export class Place extends Component {
  constructor(props) {
    super(props);
    this.queryParams = (props.location && props.location.search) ? queryString.parse(props.location.search) : null
    this.eventUpdateIntervalFnId = null;
    this.placeId = this.props.match.params.id ? this.props.match.params.id : null;
    this.eventsByGroupId = {}
    this.fromDateTs = null
    this.toDateTs = null
    this.latestEventTs = null
    this.lastEventTs = null
    this.selectedCamIds = this.getSelectedCamIds()
    this.selectedCam =  null
    this.attributes = {}
    this.parentAttributes = {}
    this.liveUrl = null
    this.tagFilters = []
    this.priorityFilters = []
    this.selectedPlaceIds = this.getSelectedPlaceIds()
    this.tagValue = {}
    this.dateMode = 'recent'
    this.selectAllOption = AppHelper.getSelectAllOption()
    this.enableFrButton = false
    this.state = {
      fetchState: ApiHelper.State.LOADING,
      pageNumber: 0,
      selectedEvent: null,
      selectedRelatedEvent: null,
      paginate: false,
      eventIndex: 0,
      eventLatest: null,
      eventEditor: false,
      eventListLoading: false,
      eventList: [],
      eventCompleteList: [],
      relativeImageSliderMode: false,
      showStats: false,
      lastEventId: null,
      faceList:[],
      enableDeepSearch:false,
      eecmToken:'',
      intType:''
    }
    this.camPicker = {
      key: 'selectedCamIds',
      type: 'checkbox',
      options: [],
    }
    this.placePicker = {
      key: 'selectedPlaceIds',
      type: 'checkbox',
      options: [],
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const settings = Controller.get().userMgr().getSettings()
    let camValue = null
    if (settings && settings.userSettings && settings.userSettings.camStat) {
      if (settings.userSettings.camStat === 'ON') {
        camValue = true
      } else {
        camValue = false
      }
      this.setState({
        showStats: camValue
      })
    }
    this.fetchItems()
  }

  fetchCookies(token, baseUrl){
    var requestOptions = {
      method: 'GET',
      headers: {
        "Authorization" : "Bearer "+token
      },
      credentials: 'include'
    };

      // fetch("https://"+ baseUrl + "/media/session", requestOptions).then(response => {let data = response.json() ; console.log(data); return data} )
      fetch("https://api.c001.eagleeyenetworks.com/api/v3.0" + "/media/session", requestOptions).then(response => {let data = response.json() ; console.log(data); return data} )
      .then( body => fetch(body.url, requestOptions) )
      .then( response => console.log("response status", response.status ) )
      .catch(error => console.log('error', error));

  }

  fetchToken(orgId, intType) {
    ApiHelper.call(
      {
        method: "GET",
        endPoint: ApiHelper.makeUrlPath(["cm", intType == "eevms" ? "vmsToken" : "token"], { orgId: orgId }),
      },
      function (err, result) {
        if (err) {
          // this.setState({
          //   fetchState: ApiHelper.State.ERROR,
          //   errMsg: Helper.getErrorMsg(err),
          // });
          return;
        }

        if (intType == "eevms") {
          this.fetchCookies(result.token, result.baseUrl)
        } else {
          this.setState(
            {
              eecmToken: result.token,
            });
        }
      }.bind(this)
    );
  }


  fetchCams(orgId, doUpdate) {
    ApiHelper.call(
      {
        method: "GET",
        endPoint: ApiHelper.makeUrlPath(["places"], { orgId: orgId }),
      },
      function (err, results) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err),
          });
          return;
        }
        results.sort((a, b) => a.name.localeCompare(b.name));
        this.setAllPlaceFields(results);

        this.setState(
          {
            org: results,
            // place: results.length > 0 ? results[0] : null,
          },
          () => {if(!doUpdate){this.updateEvents("place")}}
        );
      }.bind(this)
    );
  }

  componentWillUnmount() {
    if (this.eventUpdateIntervalFnId) {
      clearInterval(this.eventUpdateIntervalFnId);
    }
    this.eventUpdateIntervalFnId = null
  }

  fetchItems = () => {
    ApiHelper.call(
      {
        method: "GET",
        endPoint: ApiHelper.makeUrlPath(["places", this.placeId]),
      },
      function (err, result) {
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err),
          });
          return;
        }
        // this.setBreadcrumbs(result)
        let camIntType = result.cams[0].intType[0] ? result.cams[0].intType[0] :''
        if (result && result.cams.length > 0) {
          if (this.selectedCamIds[0] == AppHelper.getSelectAllOption().key) {
            for (const cam of result.cams) {
              this.selectedCamIds.push(cam._id)
            }
          } else
            if (!this.selectedCamIds || this.selectedCamIds.length === 0) {
              this.selectedCamIds = [result.cams[0]._id];
              this.selectedCam = result.cams[0]
              this.liveUrl = result.cams[0].urlJpeg;
            } else {
              for (const cam of result.cams) {
                if (this.selectedCamIds[0] === cam._id) {
                  this.selectedCam = cam

                  this.enableFrButton = cam.settings && cam.settings.webcam1 && cam.settings.webcam1.webcam1_unauthorizedFace && cam.settings.webcam1.webcam1_unauthorizedFace == "YES"
                  this.liveUrl = cam.urlJpeg;
                  break;
                }
              }
            }
          this.setState(
            {
              place: result,
              intType : camIntType
            },
            () => {

              this.setCamFields(result, true);
              this.fetchCams(result.orgId, true);

              if (AppConfig.SHOW_VIDEO) {
                this.fetchToken(result.orgId,camIntType )
              }
              this.updateEvents()
            }
          );
        } else {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getString("camNotFound"),
          });
        }
      }.bind(this)
    );
  }

  setBreadcrumbs = (item) => {
    this.breadcrumbs = [
      {
        title: AppHelper.getPlaceAltName(item.org),
        to: '/orgs/' + item.orgId + '/places'
      },
      {
        title: 'Live Grid',
        to: '/places/' + item._id + '/cams'
      },
      {
        title: 'Events',
        to: ''
      },
    ]
  }

  getSelectedCamIds = () => {
    if (!this.queryParams || !this.queryParams.camId) {
      return []
    }

    const valueStr = this.queryParams.camId
    if (valueStr) {
      return valueStr.split(',')
    } else {
      return []
    }
  }
  getSelectedPlaceIds = () => {
    return [this.placeId]
  }

  setCamFields = (place, doUpdate) => {
    this.camPicker.options = []
    this.camPicker.options.push(this.selectAllOption)
    place.cams.sort((a, b) => a.name.localeCompare(b.name));

    for (const f of place.cams) {
      this.camPicker.options.push({
        name: f.name,
        key: f._id
      })
    }
    if(!doUpdate){
      this.updateEvents()
    }
  }

  setPlaceFields = (place) => {
    this.placePicker.options = []
      this.placePicker.options.push(this.selectAllOption)
      this.placePicker.options.push({
      name: place.name,
      key: place._id
      })
  }

  setFields = (results) => {
    this.camPicker.options = [];
    const selectAllField = AppHelper.getSelectAllOption();
    if (!results) {
      for (const o of this.state.org) {
        if (this.selectedPlaceIds.indexOf(o._id) >= 0) {
          if (o.cams && o.cams.length > 0) {
            if (this.camPicker.options.indexOf(selectAllField) === -1) {
              this.camPicker.options.push(selectAllField);
            } 
            for (const cam of o.cams) {
              this.camPicker.options.push({
                key: cam._id,
                name: cam.name,
              });
            }
          }
        }
      }
    }

  }

  setAllPlaceFields = (places) => {
      this.placePicker.options = []
      this.placePicker.options.push(this.selectAllOption)
      for (const f of places) {
      this.placePicker.options.push({
        name: f.name,
        key: f._id
      })
    }
  }

  getLatestEvents = (newList, oldList) => {
    let arr = []
    if (!newList || !oldList || newList.length == 0 || oldList.length == 0) return []
    for (let index = 0; index < newList.length; index++) {
      const element = newList[index];
      if (oldList && element._id != oldList[0]._id) {
        arr[index] = element
      }else if(oldList && element._id == oldList[0]._id && element.streamUrls && !oldList.streamUrls){
        arr[index] = element
      } else {
        break
      }
    }
    return arr
  }

  fetchNotificationUsers = (call, data) => {
    // if (call) { return call() } else {
    //   return
    // }
    if(data && data.type != "alertTeams"){
      return call()
    }
    async.parallel(
      [
        (callback) => {
          ApiHelper.call(
            { method: 'GET', endPoint: ApiHelper.makeUrlPath(['faces'], { camId: this.selectedCamIds}) }, callback)
        },
      ],
      function (err, faces) {
        faces = faces[0]
        if (err) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getErrorMsg(err)
          })
          return
        }
        // for (let index = 0; index < faces.length; index++) {
        //   const element = faces[index];
        //   faceMap[element._id] = element.name
        // }
        this.setState({
          faceList: faces,
        }, ()=>{
          if(call){
            call()
          }
        })

      }.bind(this))
  }

  // updateFaceTag= (item)=>{
  //   if(item && item.tags && item.tags.faces && item.tags.faces.length > 0 && Object.keys(faceMap).length > 0){
  //     for (let index = 0; index < item.tags.faces.length; index++) {
  //       let faceId = item.tags.faces[index];
  //       let name = faceMap[faceId] 
  //       if(name){
  //         item.tags["name-"+(index+1)] = name
  //       }else{
  //         item.tags["name-"+(index+1)] = "Deleted User"    
  //       }
  //     }
  //     // delete tags.faces
  //   }
  // }

  // addNameTags = (eventList)=>{
  //   for (let index = 0; index < eventList.length; index++) {
  //     const element = eventList[index];
  //     if(element.tags && element.tags.faces && element.tags.faces.length > 0){
  //     this.updateFaceTag(element)}
  //   }
  // }

  updateEvents = (action, callback, condition) => {
    if (!this.selectedCamIds || this.selectedCamIds.length === 0 || !this.selectedPlaceIds || this.selectedPlaceIds.length == 0) {
      this.setState({
        eventList: [],
        eventCompleteList: [],
      });
      return;
    }

    const params = {
      priority: AppHelper.getGlobalPriotiyValue(),
      limit: AppConfig.dashboardEventQueryLimit,
    };

    if (this.fromDateTs && this.toDateTs) {
      params.fromDateTs = this.fromDateTs;
      params.toDateTs = this.toDateTs;
    }
    params.camId = this.selectedCamIds;
    if (params.camId.includes(AppHelper.getSelectAllOption().key)) {

      params.camId = [AppHelper.getSelectAllOption().key]
      params.placeIds = this.selectedPlaceIds

      params.placeIds = params.placeIds.filter(function (value) {
          return !value.includes((AppHelper.getSelectAllOption()).key)
      });

    }
    params.pageNumber = this.state.pageNumber;

    if (action === "place" || action === "cam" || action === "time") {
      this.setState({
        eventList: [],
        eventListLoading: true,
      });
    }

    if (action === "next") {
      this.setState({
        eventList: [],
        eventListLoading: true,
      });

      if (!this.state.paginate) {
        return;
      }

      params.pageNumber = this.state.pageNumber + 1;
      // if(this.state.enableDeepSearch){ 
      //    params.search = this.searchText ? this.searchText : '';
      // }
    }

    if (action === "prev") {
      this.setState({
        eventList: [],
        eventListLoading: true,
      });

      if (this.state.pageNumber === 0) {
        return;
      }

      params.pageNumber = this.state.pageNumber - 1;
      // params.search = this.searchText ? this.searchText : '';

    }
    if (action === "deepSearch" || (this.state.enableDeepSearch && this.searchText)) {
      this.setState({
        eventList: [],
        eventListLoading: true,
        enableDeepSearch:this.searchText ?true:false
      });

      params.search = this.searchText ? this.searchText : '';
    }
    const options = {
      method: "GET",
      endPoint: ApiHelper.makeUrlPath(["camevents"], params),
    };

    ApiHelper.call(
      options,
      function (err, result) {
        if (err) {	
          // this.setState({	
          //   fetchState: ApiHelper.State.ERROR,	
          //   errMsg: Helper.getString('defaultErrorMsg'),	
          // });	
          if(this.state.eventList.length == 0){	
            this.setState({	
              fetchState: ApiHelper.State.ERROR,	
              errMsg: Helper.getString('noEventsFound'),	
              eventListLoading:false
            })	
           	
          }	
          return;	
        }
        if (result && this.state.eventList.length == 0 && result.items.length == 0) {
          this.setState({
            fetchState: ApiHelper.State.ERROR,
            errMsg: Helper.getString('noEventsFound'),
            eventListLoading:false
          });
          return
        }
        
        // if(this.state.place.type == "alertTeams"){
        // this.addNameTags(result.items)
        // }
        
        const modifiedEventList = this.processEvents(result.items);
        const currSelectedEvent = this.getCurrSelectedEvent(modifiedEventList);

        let eventLatest = this.state.eventLatest;
        if (this.state.pageNumber === 0) {
          eventLatest = result.items.length > 0 ? result.items[0] : null;
        }
        let distinctEventList = []
        if (
          this.state.lastEventId && eventLatest && this.state.eventList.length > 0 &&
          this.state.lastEventId == eventLatest["_id"] && eventLatest.streamUrls
        ) {
          this.setState({
          fetchState: ApiHelper.State.READY,
          errMsg:""
          })
        //  return
        }else{
           distinctEventList = this.getLatestEvents(JSON.parse(JSON.stringify(modifiedEventList)),JSON.parse(JSON.stringify(this.state.eventList)))
        }
        this.setState({ lastEventId: eventLatest["_id"] });

        this.setEventTsRange(result.items);

        if(!condition ||( currSelectedEvent.event && currSelectedEvent.event.streamUrls && (!this.state.selectedEvent || !this.state.selectedEvent.streamUrls))){
          this.setState({
            selectedEvent: currSelectedEvent.event,
            selectedRelatedEvent: currSelectedEvent.relatedEvent,
          })
        }
        
        this.setState({
          pageNumber: result.currPageNumber,
          paginate: result.more ? true : false,
          eventList: this.state.eventList.length == 0 ? modifiedEventList : this.getUpdatedList(modifiedEventList, distinctEventList),
          eventLatest: eventLatest,
          eventIndex: currSelectedEvent.eventIndex,
          eventCompleteList: result.items,
          eventListLoading: false,
          fetchState: ApiHelper.State.READY,
          errMsg:""
        }, ()=>{
          if (callback) {
            callback()
          }
        });
      }.bind(this)
    );
  }

  getUpdatedList = (oldList, distinctList) =>{ 
    oldList.shiftRight(distinctList);
    return oldList
  }

  setEventTsRange(items) {
    if (items && items.length > 0) {
      const lastIndex = items.length - 1
      this.lastEventTs = items[lastIndex].event_time
      this.latestEventTs = items[0].event_time
    }
  }

  getCurrSelectedEvent = (modifiedEventList) => {
    if (!this.state.selectedEvent) {
      return {
        event: modifiedEventList.length > 0 ? modifiedEventList[0] : null,
        eventIndex: 0,
        relatedEvent: null
      }
    }

    let index = 0
    for (const item of modifiedEventList) {
      if (item._id === this.state.selectedEvent._id) {
        if (item.metadata && item.metadata.groupId && this.state.selectedRelatedEvent) {
          for (const rItem of this.eventsByGroupId[item.metadata.groupId]) {
            if (rItem._id === this.state.selectedRelatedEvent._id) {
              return {
                event: item,
                eventIndex: index,
                relatedEvent: rItem
              }
            }
          }
        } else {
          return {
            event: item,
            eventIndex: index,
            relatedEvent: null,
          }
        }
      }
      index = index + 1
    }

    return {
      event: modifiedEventList.length > 0 ? modifiedEventList[0] : null,
      eventIndex: 0,
      relatedEvent: null,
    }
  }

  updateUrl = () => {
    let camIdStr = ''
    if(this.selectedCamIds.includes("selectAll")){
      camIdStr = this.selectAllOption.key
    }else{
    for (const id of this.selectedCamIds) {
      if (id === this.selectAllOption.key) continue
      if (camIdStr) {
        camIdStr = camIdStr + ',' + id
      } else {
        camIdStr = id
      }
    }
  }
    this.props.history.replace('/places/' + this.placeId + '?camId=' + camIdStr)
  }

  processEvents = (items) => {
    items = this.filterWithParams(items)
    this.eventsByGroupId = {}
    const modifiedEventList = []
    for (const item of items) {
      if (item.metadata && !item.metadata.groupId) {
        modifiedEventList.push(item)
        continue
      }

      if (!item.metadata || !item.metadata.groupId || this.eventsByGroupId[item.metadata.groupId] === undefined) {
        modifiedEventList.push(item)
        if(!item.metadata){
          item.metadata = {}
        }

        this.eventsByGroupId[item.metadata.groupId] = []
      } else {
        this.eventsByGroupId[item.metadata.groupId].push(item)
      }
    }
    return modifiedEventList
  }

  timeChangeHandler = (fromDateTs, toDateTs, mode) => {
    this.fromDateTs = fromDateTs
    this.toDateTs = toDateTs
    this.dateMode = mode
    this.setState({
      pageNumber: 0
    }, () => this.updateEvents('time'))
  }

  camSelectHandler = (camIds) => {
    this.selectedCamIds = camIds
    this.updateUrl()
    this.setState({
      pageNumber: 0
    }, () => this.updateEvents('cam'))
  }

  placeSelectHandler = (placeIds) => {
    this.selectedPlaceIds  = placeIds
    this.setFields()
    this.updateEvents('place')
  }

  filterHandler = (tagFilters, priorityFilters, tagValue) => {
    this.tagFilters = tagFilters
    this.priorityFilters = priorityFilters
    this.tagValue = tagValue
    const filteredItems = this.filterWithParams(this.state.eventCompleteList)
    const modifiedEventList = this.processEvents(filteredItems)
    const currSelectedEvent = this.getCurrSelectedEvent(modifiedEventList)
    this.setState({
      eventList: modifiedEventList,
      selectedEvent: currSelectedEvent.event,
      selectedRelatedEvent: currSelectedEvent.relatedEvent,
      eventIndex: currSelectedEvent.eventIndex,
    })
  }

  eventRefreshHandler = (refresh) => {
    if (refresh) {
      this.startAutoUpdate()
    } else {
      this.stopAutoUpdate()
    }
  }

  searchHandler = (searchText, disableFrontendSearch) => {
    this.searchText = searchText
    if(searchText == "" && this.state.enableDeepSearch){
      this.searchText = ""
      this.updateEvents("deepSearch")
      return
    }
    if(disableFrontendSearch)return

    const filteredItems = this.filterWithParams(this.state.eventCompleteList)
    const modifiedEventList = this.processEvents(filteredItems)
    const currSelectedEvent = this.getCurrSelectedEvent(modifiedEventList)
    this.setState({
      eventList: modifiedEventList,
      selectedEvent: currSelectedEvent.event,
      selectedRelatedEvent: currSelectedEvent.relatedEvent,
      eventIndex: currSelectedEvent.eventIndex,
    })
  }

  filterWithParams = (items) => {
    let eventListAfterFilter = []
    if (this.searchText) {
      eventListAfterFilter = SearchHelper.filterItemsBySearchText(this.searchText, items)
    } else {
      eventListAfterFilter = items
    }

    if (this.tagFilters && this.tagFilters.length > 0) {
      eventListAfterFilter = SearchHelper.filterItemsByTag(this.tagFilters, this.tagValue, eventListAfterFilter)
    }

    if (this.priorityFilters && this.priorityFilters.length > 0) {
      eventListAfterFilter = SearchHelper.filterItemsByPriority(this.priorityFilters, eventListAfterFilter)
    }
    return eventListAfterFilter
  }

  onEventTileSelect = (item, index) => {
    this.setState({
      selectedEvent: item,
      eventIndex: index,
      selectedRelatedImage: 0,
      selectedRelatedEvent: null,
    })

  }

  onRelatedEventTileClick = (item) => {
    this.setState({
      selectedRelatedEvent: item
    })
  }

  startAutoUpdate = () => {
    this.eventUpdateIntervalFnId = setInterval(() => {
      if (this.state.pageNumber === 0) this.updateEvents(this.state.enableDeepSearch ? "deepSearch":undefined,undefined,true);
    }, AppConfig.eventRefreshInterval);
  }

  stopAutoUpdate = () => {
    if (this.eventUpdateIntervalFnId) {
      clearInterval(this.eventUpdateIntervalFnId);
      this.setState({
        restoreUpdate: true
      })
    }
    this.eventUpdateIntervalFnId = null
  }

  enableAutoUpdate = () => {
    if (this.state.restoreUpdate) {
      this.eventUpdateIntervalFnId = setInterval(() => {
        if (this.state.pageNumber === 0) this.updateEvents();
      }, AppConfig.eventRefreshInterval);
      this.setState({
        restoreUpdate: false
      })
    }
  }

  closeEventEditor = () => {
    this.setState({
      eventEditor: false,
      selectedEditItem: {}
    })
    this.enableAutoUpdate()
  }

  applyGenAI = (item)=>{

    const params = {
      genAI: true
    };
    let options = {}
      options = {
        method: 'GET',
        endPoint: ApiHelper.makeUrlPath(['camevents',item._id], params),
      }

      ApiHelper.call(options, function (err, data) {
        if (err) {
          const msg = err.message ? err.message : Helper.getString('defaultErrorMsg')
          this.setState({
            errMsg: msg,
            errCode: err.respCode,
          })
          return
        }
        this.setState({
          errMsg: '',
          errCode:'',
        })
        // this.updateEvents(undefined,callback, false)
        this.updateEventList(data)
      }.bind(this))
    
  }

  updateEventList = (item) => {
    let eventList = this.state.eventList
    for (let index = 0; index < eventList.length; index++) {
      const element = eventList[index];
      if(element._id == item._id){
        eventList[index] = JSON.parse(JSON.stringify(item))
        break
      }
    }
    
    this.setState({
      eventList:eventList,
      selectedEvent:item
    })
    
  }

  showEventEditor = (item) => {
    let prioritySelected
    for (const p of adminData.priorityValues) {
      if (item.priority === p.value) {
        prioritySelected = p
      }
    }
    this.setState({
      eventEditor: true,
      selectedEditItem: item,
      prioritySelected: prioritySelected
    })
    this.stopAutoUpdate();
  }

  editEvent = (priority, mode, callback) => {
    let options = {}
    if (mode === 'save') {
      options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['camevents', this.state.selectedEditItem._id]),
        jsonBody: {
          priority: Number(priority)
        }
      }
    } else if (mode === 'delete') {
      options = {
        method: 'DELETE',
        endPoint: ApiHelper.makeUrlPath(['camevents', this.state.selectedEditItem._id]),
      }
    }  else if (mode === 'addComment') {

        let id = priority.selectedEditItem._id
        delete priority.selectedEditItem

        options = {
        method: 'PUT',
        endPoint: ApiHelper.makeUrlPath(['camevents', id]),
        jsonBody: {
          latestComment: priority
        }
      }
    }
    else {
      return
    }

    ApiHelper.call(options, function (err, data) {
      if (err) {
        const msg = err.message ? err.message : Helper.getString('defaultErrorMsg')
        this.setState({
          errMsg: msg,
          errCode: err.respCode,
        })
        return
      }
      this.setState({
        eventEditor: false,
        errMsg: '',
        errCode:'',
      })
      this.updateEvents(undefined,callback, false)

    }.bind(this))
  };

  downloadReportOld = () => {
    Controller.get().pushRouteWithParams('/reports/' + this.placeId,
      {
        selectedCamIds: this.selectedCamIds,
        priorityFilters: this.priorityFilters,
        tagFilters: this.tagFilters,
        tagValue: this.tagValue,
        fromDateTs: this.fromDateTs,
        toDateTs: this.toDateTs
      })
  }

  downloadReport = () => {
    Controller.get().pushRouteWithParams('/orgs/' + this.state.place.orgId + '/reports',
      {
        selectedCamIds: this.selectedCamIds,
        priorityFilters: this.priorityFilters,
        tagFilters: this.tagFilters,
        tagValue: this.tagValue,
        fromDateTs: this.fromDateTs,
        toDateTs: this.toDateTs,
        selectedPlaceIds: [this.placeId]
      })
  }

  imageSliderHandler = (sliderMode) => {
    this.setState({
      relativeImageSliderMode: sliderMode
    })
  }

  showStatsHandler = (value) => {
    let camValue = null
    if (value) {
      camValue = 'ON'
    } else {
      camValue = 'OFF'
    }
    this.setState({ showStats: value }, () => Controller.get().userMgr().setUserSettings({ camStat: camValue }))
  }

  render() {
    if (this.state.fetchState === ApiHelper.State.LOADING) {
      return UiHelper.componentLoadingView();
    } else if (this.state.fetchState === ApiHelper.State.ERROR) {
      // return UiHelper.errorView(this);
      return this.readyView();

    } else {
      return this.readyView();
    }
  }

  setCamAttributes = () => {
    if (this.selectedCamIds && this.selectedCamIds.length === 1) {
      for (const c of this.state.place.cams) {
        if (c._id === this.selectedCamIds[0]) {
          this.attributes = c.attributes || {}
          //TODO
          const camType = c.type || 'general'
          for (const p of AppConfig.camTypes) {
            if (p.key === camType) {
              this.parentAttributes = p.attributes
              this.camId = c._id
              break
            }
          }
          return
        }
      }
    }
    this.attributes = {}
    this.parentAttributes = {}
  }

  deepSearch = (searchText) =>{
    this.updateEvents("deepSearch")
  }
  readyView() {
    return (
      <div className={css(CommonStyles.outerMainContainer)}>
        <EventActionHeaderbar
          // title={this.state.place ? this.state.place.name : ''}
          backPathName={'/places/' + this.placeId + '/cams'}
          breadcrumbs={this.breadcrumbs}
          placePicker={this.placePicker}
          placeSelectHandler={this.placeSelectHandler}
          showDownload={true}
          timeChangeHandler={this.timeChangeHandler}
          camSelectHandler={this.camSelectHandler}
          filterHandler={this.filterHandler}
          selectedPlaceIds={this.selectedPlaceIds}
          eventRefreshHandler={this.eventRefreshHandler}
          camPicker={this.camPicker}

          showStats={this.state.showStats}
          place={this.state.place}
          showSummaryButton={this.attributes && Object.keys(this.attributes).length !== 0 ? true : false}
          showStatsHandler={this.showStatsHandler}
          relativeImageSliderMode={this.state.relativeImageSliderMode}
          selectedCamIds={this.selectedCamIds}
          downloadReport={this.downloadReport}
        />

   

        {this.state.eventCompleteList &&
          this.state.eventCompleteList.length > 0  ?
          <div>
            <div className={css(CommonStyles.innerMainContainer)}>
              <div className={css(Styles.innerContainer)}>
                <div>
                  {(this.attributes &&
                    Object.keys(this.attributes).length > 0) &&
                    this.selectedCamIds &&
                    this.selectedCamIds.length === 1 &&
                    <StatsComponent
                      mode={'cam'}
                      entityId={this.camId}
                      parentAttributes={this.parentAttributes}
                      attributes={this.attributes}
                      showStats={this.state.showStats}
                      showStatsHandler={this.showStatsHandler}
                      state={this.state}
                      refresh={this.fetchItems}
                    />
                  }
                </div>

                <div className={css(Styles.innerSubContainer)}>
                  <EventList
                    dateMode={this.dateMode}
                    selectedEvent={this.state.selectedEvent}
                    lastEventTs={this.lastEventTs}
                    latestEventTs={this.latestEventTs}
                    eventsByGroupId={this.eventsByGroupId}
                    onEventTileSelect={this.onEventTileSelect}
                    searchHandler={(val)=>this.searchHandler(val, true)}
                    paginate={this.state.paginate}
                    pageNumber={this.state.pageNumber}
                    updateEvents={this.updateEvents}
                    eventList={this.state.eventList}
                    relativeImageSliderMode={this.state.relativeImageSliderMode}
                    eventListLoading={this.state.eventListLoading}
                    editEvent={this.editEvent}
                    selectedEditItem={this.state.selectedEvent}
                    deepSearch={this.deepSearch}

                  />
                  {this.state.fetchState === ApiHelper.State.ERROR ?
                    <p className={css(Styles.emptyEventContainer, Styles.emptyEventContainer2)}>{Helper.getString('noEventsFound')}</p> :
                    <EventDetails
                      liveUrl={this.liveUrl}
                      intType={this.state.intType}
                      enableFrButton = {this.enableFrButton}
                      eecmToken = {this.state.eecmToken}
                      enableFR={true}
                      updateFaceData={() => this.fetchNotificationUsers()}
                      autoCompleteData={this.state.faceList}
                      placeId={this.placeId}
                      camId={(this.selectedCamIds && this.selectedCamIds.length > 0) ? this.selectedCamIds[0] : null}
                      searchHandler={this.searchHandler}
                      eventsByGroupId={this.eventsByGroupId}
                      eventList={this.state.eventList}
                      onEventTileSelect={this.onEventTileSelect}
                      onRelatedEventTileClick={this.onRelatedEventTileClick}
                      imageSliderHandler={this.imageSliderHandler}
                      selectedEvent={this.state.selectedEvent}
                      selectedRelatedEvent={this.state.selectedRelatedEvent}
                      relativeImageSliderMode={this.state.relativeImageSliderMode}
                      eventIndex={this.state.eventIndex}
                      showEventEditor={this.showEventEditor}
                      applyGenAI={this.applyGenAI}
                      refresh={this.fetchItems}
                      editEvent={this.editEvent}
                      updateEvents={this.updateEvents}
                      commentErr={this.state.errMsg}
                      selectedEditItem={this.state.selectedEvent}
                      selectedCam = {this.selectedCam}
                      />
                  }


                </div>
              </div>
            </div>
          </div>:
            <p className={css(Styles.emptyEventContainer, Styles.emptyEventContainer2)}>{Helper.getString('noEventsFound')}</p> 
        }

        {this.state.eventEditor  &&
          <EventEditor
            eventEditor={this.state.eventEditor}
            closeEventEditor={this.closeEventEditor}
            selectedEditItem={this.state.selectedEditItem}
            editEvent={this.editEvent}
            prioritySelected={this.state.prioritySelected}
          />
        }

      </div>
    )
  }
}
