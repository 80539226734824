/* eslint-disable */ 
import { UserMgr } from './mgrs/UserMgr'
import { LoginMgr } from './mgrs/LoginMgr';

export class Controller {
  static _me = null;
  static get() {
    if (this._me == null) {
      this._me = new Controller();
    }
    return this._me;
  }

  constructor() {
  }

  _userMgrObj = null;
  userMgr() {
    if (null == this._userMgrObj) {
      this._userMgrObj = new UserMgr();
    }
    return this._userMgrObj;
  }

  _loginMgrObj = null;
  loginMgr() {
    if (null == this._loginMgrObj) {
      this._loginMgrObj = new LoginMgr();
    }
    return this._loginMgrObj;
  }

  logout() {
    this.loginMgr().signOut();
    this.pushRoute('/login')
  }

  setNavigator(history) {
    this.history = history
  }

  pushRouteWithParams(route, params) {
    this.history.push({ pathname: route, state: params })
  }

  pushRouteWithQueryParams(route, queryParams, state) {
    this.history.push({ pathname: route, search: queryParams, state: state })
  }

  pushRoute(route) {
    this.history.push(route)
  }

  pushRouteBack() {
    this.history.goBack()
  }
  getNavigator(){
    return this.history
  }
}
