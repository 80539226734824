/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Styles } from './Styles'
import { CommonStyles } from '../styles/Styles';
import Icon from '@material-ui/core/Icon';
import { AppHelper } from '../helpers/AppHelper';
import { Controller } from '../common/Controller';

export class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appUser: Controller.get().userMgr().getUser(),
    }
  }

  componentDidMount() { }

  render() {
    return (
      <div className={css(Styles.orgDetailContainer)}>
        <div className={css(Styles.orgDetailInnerContainer)}>
          <div className={css(Styles.orgDetailContainerTilte)}>
            <p className={css(Styles.orgDetailContainerTiltContent)}>ACCOUNT INFO</p>
          </div>


          {
            this.props.guests &&
            <div className={css(Styles.orgDetailInnerContent)}>
              <div className={css(Styles.imgOuterContainer)}>
                {!this.props.logoUrl &&
                  <Icon
                    className={css(Styles.defaultIcon)}>
                    domain
                  </Icon>
                }
                {this.props.logoUrl &&
                  <img
                    src={this.props.logoUrl}
                    onError={(e) => {
                      e.target.src = require('../assets/images/error.svg')
                    }}
                    className={css(Styles.ImgContent)} />
                }
              </div>
              <div>
                <p className={css(CommonStyles.headerMemberCountContainer)}>Account id - {this.props.title}</p>
              </div>
            </div>
          }

          {this.props.org &&
            <div className={css(Styles.orgDetailInnerContent)}>
              <div className={css(Styles.imgOuterContainer)}>
                {!this.props.org.logoUrl &&
                  <Icon
                    className={css(Styles.defaultIcon)}>
                    domain
                  </Icon>
                }
                {this.props.org.logoUrl &&
                  <img
                    src={this.props.org.logoUrl}
                    onError={(e) => {
                      e.target.src = require('../assets/images/error.svg')
                    }}
                    className={css(Styles.ImgContent)} />
                }
              </div>
              <div>
                <p className={css(CommonStyles.headerMemberCountContainer)}>Account id - {this.props.org.name}</p>
                {this.props.org.email &&
                  <div className={css(Styles.contactContainer)}>
                    <img src={require('../assets/images/email@.svg')} />
                    <p className={css(Styles.contact)}>{this.props.org.email}</p>
                  </div>
                }
                {this.props.org.phone &&
                  <div className={css(Styles.contactContainer)}>
                    <img src={require('../assets/images/phone.svg')} />
                    <p className={css(Styles.contact)}>{this.props.org.phone}</p>
                  </div>
                }
              </div>
            </div>
          }
        </div>

        <div className={css(Styles.orgDetailInnerContainer, Styles.orgDetailInnerContainerSecondary)}>
          <div className={css(Styles.orgDetailContainerTilte)}>
            <p className={css(Styles.orgDetailContainerTiltContent)}>OVERALL STATUS</p>
          </div>

          <div className={css(Styles.orgDetailInnerContent)}>
            {this.props.org && 
              (!this.state.appUser.email.toLowerCase().includes('eevms')) &&
              <div>
                <p className={css(Styles.overrallContainerTitle)}>{AppHelper.getPlaceAltName(this.props.org).toUpperCase() + 'S'}</p>
                <p className={css(Styles.overrallContainerContent)}>{this.props.places.length}</p>
              </div>
            }
            {this.props.cams &&
              <div className={css(Styles.overrallContainer)}>
                <p className={css(Styles.overrallContainerTitle)}>CAMERAS</p>
                <p className={css(Styles.overrallContainerContent, Styles.overrallContainerContentSecond)}>{this.props.cams.length}</p>
              </div>
            }
            {this.props.users &&
              <div className={css(Styles.overrallContainer)}>
                <p className={css(Styles.overrallContainerTitle)}>USERS</p>
                <p className={css(Styles.overrallContainerContent, Styles.overrallContainerContentThird)}>{this.props.users.length}</p>
              </div>
            }
            {this.props.guests &&
              <div className={css(Styles.overrallContainer)}>
                <p className={css(Styles.overrallContainerTitle)}>Vehicles</p>
                <p className={css(Styles.overrallContainerContent, Styles.overrallContainerContentThird)}>{this.props.guests.length}</p>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}