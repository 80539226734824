/* eslint-disable */ 
import { Helper } from '../common/helpers/Helper';
import { PhoneNumberHelper } from '../common/helpers/PhoneNumberHelper';
import { parseNumber } from 'libphonenumber-js';
import { AppConfig } from '../AppConfig';

export class ValidationHelper {
  static validateFields(formField, value) {
    if (formField.required
      && formField.required.value &&
      (!value || value.length === 0)) {
      return formField.required.err || null
    } else if (formField.email &&
      formField.email.value &&
      value && !Helper.validateAndFormatEmailV2(value)) {
      return formField.email.err || null
    } if (formField.phone &&
      formField.phone.value &&
      value && !PhoneNumberHelper.formatNumber(value)) {
      return formField.phone.err || null
    } if (formField.v_float &&
      formField.v_float.value &&
      value && (!ValidationHelper.isFloat(value))) {
      return formField.v_float.err || null
    } if (formField.v_int &&
      formField.v_int.value &&
      value && (!ValidationHelper.isInt(value))) {
      return formField.v_int.err || null
    } else {
      return null
    }
  }

  static validateFieldsAndFormat(formField, value) {
    let err = null, valueFormatted = value
    if (formField.required
      && formField.required.value &&
      (!value || value.length === 0)) {
      err = formField.required.err
    }

    if (formField.email &&
      formField.email.value &&
      value) {
      if (!Helper.validateAndFormatEmailV2(value)) {
        err = formField.email.err
      } else {
        valueFormatted = Helper.validateAndFormatEmailV2(value)
      }
    }

    if (formField.phone &&
      formField.phone.value &&
      value) {
      if (!PhoneNumberHelper.formatNumber(value)) {
        err = formField.phone.err
      } else {
        valueFormatted = PhoneNumberHelper.formatNumber(value)
      }
    }

    if (formField.v_float &&
      formField.v_float.value &&
      value) {
      if (!ValidationHelper.isFloat(value)&& (ValidationHelper.isFloat(value) != 0)) {
        err = formField.v_float.err
      } else {
        valueFormatted = ValidationHelper.isFloat(value)
      }
    }

    if (formField.v_int &&
      formField.v_int.value &&
      value) {
      if (!ValidationHelper.isInt(value) && (ValidationHelper.isInt(value) != 0)) {
        err = formField.v_int.err
      } else {
        valueFormatted = ValidationHelper.isInt(value)
      }
    }

    return {
      err: err,
      valueFormatted: valueFormatted
    }
  }

  static isInt(value) {
    if (isNaN(value)) {
      return null
    }
    const numValue = Number(value)
    if (numValue % 1 !== 0) {
      return null
    }

    return numValue
  }

  static isFloat(value) {
    if (isNaN(value)) {
      return false
    }

    return Number(value)
  }

  static customCamExcelValidation = (orgType, excelData) => {
    if (!excelData || !orgType) {
      return 'An error occured'
    }

    let errorMsg = '';

    if (orgType === 'wfh') {
      const phoneDict = {};
      const emailDict = {};
      const rowDict = {};

      for (let i = 0; i < excelData.length; i++) {
        const row = excelData[i]
        const line = 'Row number ' + (i + 2) + ' : '
        let errorLine = '';
  
        errorLine = ValidationHelper.verifyName(row, errorLine, line);
        errorLine = ValidationHelper.verifyEmailAndPhoneV2(row, errorLine, line);
  
        if (errorLine) {
          errorMsg += errorLine
        } else {
          ValidationHelper.addToPhoneDict(row, i + 2, phoneDict, rowDict);
          ValidationHelper.addToEmailDict(row, i + 2, emailDict, rowDict);
        }
  
        if (errorMsg) {
          return errorMsg;
        } 
  
      }
  
      return ValidationHelper.handleDuplicates(emailDict, phoneDict, rowDict);
    }
    else {
      
      for (let i = 0; i < excelData.length; i++) {
        const row = excelData[i]
        const line = 'Row number ' + (i + 2) + ' : '
        let errorLine = '';

        errorLine = ValidationHelper.verifyFps(row, errorLine, line);
        errorLine = ValidationHelper.verifyCamType(row, errorLine, line);

        if (errorLine) {
          errorMsg += errorLine
        }
        
        if (errorMsg) {
          return errorMsg;
        } 
      }
      return '';
    }

  }

  static customExcelValidation = (excelData) => {
    if (!excelData) {
      return 'An error occured'
    }
    excelData.map((item, index) => {
      if (item.role) {
        excelData[index].role = item.role.split(',');
      }
      if (item.vehiclenumbers) {
        excelData[index].vehiclenumbers = item.vehiclenumbers.replace(/\s+/g, '').split(',');
      }
    })
    let errorMsg = '';
    const phoneDict = {};
    const emailDict = {};
    const rowDict = {};

    for (let i = 0; i < excelData.length; i++) {
      const row = excelData[i]
      const line = 'Row number ' + (i + 2) + ' : '
      let errorLine = '';

      errorLine = ValidationHelper.verifyName(row, errorLine, line);

      errorLine = ValidationHelper.verifyEmailAndPhoneV2(row, errorLine, line);
      errorLine = ValidationHelper.verifyRoles(row, errorLine, line);

      if (errorLine) {
        errorMsg += errorLine
      } else {
        ValidationHelper.addToPhoneDict(row, i + 2, phoneDict, rowDict);
        ValidationHelper.addToEmailDict(row, i + 2, emailDict, rowDict);
      }
    }

    //TODO: verify all are same dial codes

    if (errorMsg) {
      return errorMsg;
    } else {
      return ValidationHelper.handleDuplicates(emailDict, phoneDict, rowDict);
    }
  }

  static verifyEmailAndPhoneV2(row, errorLine, line) {
    if (!row.email) {
      errorLine += line + 'Email is mandatory' + '\n';
    } else {
      if (row.mobile) {
        if (row.dial_code) {
          row.mobile = String(row.mobile);
          if ((row.mobile).startsWith('+')) {
            errorLine += line + 'Please enter the mobile number without \'+\' sign' + '\n';
          } else {
            row.mobile = '+' + row.dial_code + row.mobile;
            try {
              const parsed = parseNumber(row.mobile)
              if (!parsed || !parsed.country || !parsed.phone) {
                errorLine += line + 'Invalid mobile' + '\n';
              }
            } catch (error) {
              errorLine += line + 'Invalid mobile' + '\n';
            }
          }
        } else {
          errorLine += line + 'Invalid dial code' + '\n';
        }
      }

      if (row.email) {
        row.email = Helper.validateAndFormatEmailV2(row.email)
        if (!row.email) {
          errorLine += line + 'Invaild email' + '\n';
        }
      }
    }

    return errorLine;
  }
  static handleDuplicates(emailDict, phoneDict, rowDict) {
    //console.log('handleDuplicates');
    let errorMsg = '';
    for (const email in emailDict) {
      let errorLine = '';
      if (emailDict[email] > 1) {
        errorLine = 'Email: ' + email + ' is repeated in rows: ' + rowDict[email] + '\n';
      }
      if (errorLine) {
        errorMsg += errorLine
      }
    }

    for (const phone in phoneDict) {
      let errorLine = '';
      if (phoneDict[phone] > 1) {
        errorLine = 'Mobile: ' + phone + ' is repeated in rows: ' + rowDict[phone] + '\n';
      }
      if (errorLine) {
        errorMsg += errorLine
      }
    }

    return errorMsg;
  }

  static addToPhoneDict(row, rowNum, phoneDict, rowDict) {
    if (row.mobile) {
      if (phoneDict[row.mobile]) {
        phoneDict[row.mobile] = phoneDict[row.mobile] + 1;
        rowDict[row.mobile].push(rowNum);
      } else {
        phoneDict[row.mobile] = 1;
        rowDict[row.mobile] = [rowNum];
      }
    }
  }

  static addToEmailDict(row, rowNum, emailDict, rowDict) {
    if (row.email) {
      if (emailDict[row.email]) {
        emailDict[row.email] = emailDict[row.email] + 1;
        rowDict[row.email].push(rowNum);
      } else {
        emailDict[row.email] = 1;
        rowDict[row.email] = [rowNum];
      }
    }
  }

  static verifyName(row, errorLine, line) {
    if (!row.name) {
      errorLine += line + 'Name is mandatory' + '\n';
    }
    return errorLine;
  }

  static verifyRoles(row, errorLine, line) { //TODO only single role is handled now
    if (!row.role) {
      errorLine += line + 'Role is mandatory' + '\n';
    }
    if (row.role) {
      row.role.map(item => {
        if (item !== 'ADMIN' && item !== 'REGULAR' && item !== 'RESIDENT') { //TODO Remove this and take from AppConfig?
          errorLine += line + 'Invalid Role' + '\n';
        }
      })
    }
    return errorLine;
  }

  static verifyCamType(row, errorLine, line) {
    if (!row.type) {
      errorLine += line + 'Type is mandatory' + '\n';
    }
    if (row.type) {
        for (const pType of AppConfig.camTypes) {
          if(row.type === pType.key) {
            return errorLine
          }
        }
    }

    errorLine += line + 'Invalid type. Possible values \n';
    for (const pType of AppConfig.camTypes) {
      errorLine += pType.key + ' ' 
    }
    errorLine +=  '\n'

    return errorLine;

  }

  static verifyFps(row, errorLine, line) {

    if (row.fps) {
      if (!Helper.isNumeric(row.fps)) {
        errorLine += line + 'Invalid fps' + '\n';
      }
    }
    return errorLine

  }
}