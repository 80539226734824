/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Styles } from './Styles'
import 'rc-slider/assets/index.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
// import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';


// import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
// import SearchIcon from '@material-ui/icons/Search';
export class SearchTextField extends Component {
  constructor(props) {
    super(props);
    this.searchField = {
      key: 'searchText',
      label: this.props.placeholder || 'Search Events',
      type: 'text'
    }
    this.state = {
      searchText: ''
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () =>{ 
      this.props.searchHandler(this.state.searchText)
    });
  }

  searchClose = () => {
    this.setState({ searchText: '' }, () => this.props.searchHandler(this.state.searchText))
  }

  render = () => {
    return (
      <div style={{display:"flex", justifyContent:"center"}}>
        <TextField
          id={this.searchField.key}
          placeholder={this.searchField.label}
          name={this.searchField.key}
          className={css(Styles.inputCss)}
          value={this.state.searchText}
          onChange={e => this.onChange(e)}
          margin='normal'
          type={this.searchField.type}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: css(Styles.textFieldContentInput),
            },
            endAdornment:
              <InputAdornment position='end'>
                {this.state.searchText === '' ?
                  this.props.disableSearchIcon ? <></> : <img src={require('../../assets/images/search-img.svg')}
                    className={css(Styles.controlImg)} />
                  :
                  <img src={require('../../assets/images/roundClose-img.svg')}
                    className={css(Styles.controlImg)} onClick={() => this.searchClose()} />
                }
              </InputAdornment>,
          }}
          InputLabelProps={{
            classes: {
              root: css(Styles.textFieldContent),
            }
          }}
        />


            {/* <Tooltip title={'SEARCH'}>
              <div className={css(Styles.eventEditImgContainer)} style={{marginRight:"0px !important"}}>
                <div className={css(Styles.eventEditImg)} onClick={() => this.props.showEventEditor(item)}>
                  <img src={require('../../assets/images/search-img.svg')} />
                </div>
              </div>
            </Tooltip> */}
        {/* <Button
          variant='contained'
          onClick={()=>this.props.deepSearch(this.state.searchText)}
          className={css(Styles.buttonStyle2)}
        >
          SEARCH
        </Button> */}
      </div>
    )
  }
}